import React, {useEffect, useState} from "react";
import "../../../app/contact/section/contactform.css";
import Formimage from "../../../../asset/form-img.png";
import { Container, Form, FormControl, InputGroup } from "react-bootstrap";
import { AnimationOnScroll } from "react-animation-on-scroll";
import 'animate.css'
import Axios from "axios";
import swal from "sweetalert";
import {useNavigate} from 'react-router-dom'
const Contactform = () => {
  const [data, setData] = useState({
    name: null,
    email: null,
    phone: null,
    message: null,
  });
  let navigate = useNavigate();
  const sendEmail = (e) => {
    console.log(data)
    e.preventDefault();
    if (data.name !== null && data.email !== null && data.phone !== null && data.message !== null) {
      Axios.post('http://localhost:8000/api/contact', data).then(res => {
        swal("Success", "success")
        navigate('/')
      })
    }
  };
  return (
    <>
      <Container>
        <div className={"row"}>
          <div className={" contact-form-heading col-lg-12 py-5"}>
            <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp">
            <p>Contact Form</p>
            <h1>
              HAVE SOME <span>QUESTION?</span>{" "}
            </h1>
            </AnimationOnScroll>
          </div>
        </div>
        <div className={"from-section  row"}>
          <div
            className={" contact-form-image col-lg-6 col-md-12 col-sm-12 py-4"}>
              <AnimationOnScroll animateOnce={true}   animateIn="animate__fadeInLeft">
            <img src={Formimage} />
              </AnimationOnScroll>
          </div>
          <div className={"col-lg-5 col-md-12 col-sm-12 py-4"}>
            <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInRight">
            <div className={"contact-form"}>
              <Form onSubmit={sendEmail}>
                <Form.Group className="mb-3" controlId="formBasicText">
                  <Form.Label>Name</Form.Label>
                  <Form.Control type="text" placeholder="Enter your name"
                                value={data.name !== null ? data.name : ''}
                                onChange={text => setData({
                                  ...data,
                                  name: text.target.value
                                })}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Email Address</Form.Label>
                  <Form.Control type="email" placeholder="Enter your email"
                                value={data.email !== null ? data.email : ''}
                                onChange={text => setData({
                                  ...data,
                                  email: text.target.value
                                })}/>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPhone">
                  <Form.Label>Phone</Form.Label>
                  <InputGroup className="mb-2">
                    <InputGroup.Text>+91</InputGroup.Text>
                    <FormControl
                      id="inlineFormInputGroup"
                      placeholder="Phone"
                      value={data.phone !== null ? data.phone : ''}
                      onChange={text => setData({
                        ...data,
                        phone: text.target.value
                      })}
                      />
                  </InputGroup>
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                  >
                  <Form.Label>Message</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Enter message"
                    value={data.message !== null ? data.message : ''}
                    onChange={text => setData({
                      ...data,
                      message: text.target.value
                    })}
                    />
                </Form.Group>
                <div className={"contact-button"}>
                  <button type="submit">Submit</button>
                </div>
              </Form>
            </div>
                    </AnimationOnScroll>
          </div>
        </div>
      </Container>
    </>
  );
};
export default Contactform;
