import React from "react";
import { Container } from "react-bootstrap";
import ModalImage from "react-modal-image";
import Infara1 from "../../../../asset/gallery/g10.jpg";
import Infara2 from "../../../../asset/gallery/g2.jpg";
import Infara3 from "../../../../asset/gallery/g3.jpg";
import Infara4 from "../../../../asset/gallery/g8.jpg";
import Infara5 from "../../../../asset/gallery/g12.jpg";
import Infara6 from "../../../../asset/gallery/g13.jpg";
import Infara7 from "../../../../asset/gallery/g4.jpg";
import Infara8 from "../../../../asset/gallery/g5.jpg";
import Infara9 from "../../../../asset/gallery/g7.jpg";
import Largeinfara1 from "../../../../asset/gallery/g1.jpg";
import Largeinfara2 from "../../../../asset/gallery/g9.jpg";
import Largeinfara3 from "../../../../asset/gallery/g6.jpg";

import "../../../../components/app/about/section/gallery.css";
import "animate.css";
import { AnimationOnScroll } from "react-animation-on-scroll";
import {VscOpenPreview} from "react-icons/vsc";

const Gallery = () => {
  return (
    <section>
      <Container>
        <div className={"row py-1"}>
          <div className={"col-lg-12 d-flex justify-content-center py-1"}>
            <div className="infara-heading">
              <h1>
                OUR <span>INFRASTRUCTURE</span>{" "}
              </h1>
            </div>
          </div>
          <div > 
          <div className={"preview "}>
          <VscOpenPreview className={"mx-2"} style={{fontSize:"20px"}} />
            <p >click to preview</p>
          </div>
            
      
          </div>

          <div className={"row model-img py-5 "}>
            <div className="col-lg-3 col-md-3 col-sm-12  ">
              <div   className={'mod-img'}>
              <ModalImage
             
             small={Infara1}
             large={Largeinfara1}
             alt="Warehouse lifecarepharma"
             hideDownload
           />
              </div>
           
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12">
              <ModalImage
             
                small={Infara2}
                large={Infara2}
                alt="Lifecarepharma building"
                hideDownload
              />

              <ModalImage
                small={Infara3}
                large={Infara3}
                alt="Warehouse lifecarepharma"
                className={"py-3"}
                hideDownload
              />
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12">
              <div className={'mod-img'}>
              <ModalImage
                small={Infara6}
                large={Largeinfara2}
                alt="Warehouse lifecarepharma"
                hideDownload
              />
              </div>
             
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12">
              <div className={'mod-img'} >

              <ModalImage
                small={Infara5}
                large={Largeinfara3}
                alt="Warehouse lifecarepharma"
                hideDownload
              />
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12">
              <ModalImage
                small={Infara4}
                large={Infara4}
                alt="Warehouse lifecarepharma"
                hideDownload
              />
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12">
              <ModalImage
                small={Infara7}
                large={Infara7}
                alt="Warehouse lifecarepharma"
                hideDownload
              />
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12">
              <ModalImage
                small={Infara8}
                large={Infara8}
                alt="Warehouse lifecarepharma"
                hideDownload
              />
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12">
              <ModalImage
                small={Infara9}
                large={Infara9}
                alt="Warehouse lifecarepharma"
                hideDownload
              />
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Gallery;
