import React, { useEffect, useState } from "react";

import Logo from "../../asset/logo.png";
import "../shared/header.css";
import { HiOutlineMenu } from "react-icons/hi";
import { Drawer } from "antd";
import { Link, useLocation } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import Slider from "react-slick/lib/slider";
import Webimg from "../../asset/weborder.jpg";
import axios from "axios";
import Img1 from '../../asset/wobanners/1.jfif';
import Img2 from '../../asset/wobanners/2.jfif';
import Img3 from '../../asset/wobanners/4.jfif';
import Img5 from '../../asset/wobanners/5.jpg';
import Img6 from '../../asset/wobanners/6.jfif';
import Img7 from '../../asset/wobanners/7.jpeg';
import Img8 from '../../asset/wobanners/8.jpeg';
import Img9 from '../../asset/wobanners/9.jpeg';
import Img10 from '../../asset/wobanners/10.jpeg';
import Img11 from '../../asset/wobanners/11.jpeg';
import Img12 from '../../asset/wobanners/12.jpeg';
import Img13 from '../../asset/wobanners/13.jpeg';
import Img14 from '../../asset/wobanners/14.jpeg';

const Header = () => {
  const [position, setPosition] = useState(0);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setPosition(window.scrollY);
    });
  }, [position]);

  const [visible, setVisible] = useState(false);
  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
  };

  const [banner, setbanner] = useState([]);
  useEffect(() => {
    axios
      .get("https://api.lifecareweborder.com/api/get/banners")
      .then((res) => {
        setbanner(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const location = useLocation();

  return (
    <>
      <nav className={`header ${position > 20 ? "header-down" : "header"}`}>
        <div className="logo">
          <Link to={"/"}>
            {" "}
            <img src={Logo} />
          </Link>
        </div>
        <div className="nav-list-item">
          <ul>
            <Link to={"/"}>
              <li
                style={{
                  color: location.pathname === "/" ? "#F7AD00" : "#4d4d4d",
                }}
              >
                Home
              </li>
            </Link>

            <Link to={"/aboutus"}>
              <li
                style={{
                  color:
                    location.pathname === "/aboutus" ? "#F7AD00" : "#4d4d4d",
                }}
              >
                About us
              </li>{" "}
            </Link>

            {/* <a href="/aboutus">
              <li
                style={{
                  color:
                    location.pathname === "/aboutus" ? "#F7AD00" : "#4d4d4d",
                }}
              >
                About Us
              </li>
            </a> */}

            <li
              onClick={handleShow}
              style={{ cursor: "pointer", color: "#4d4d4d !important" }}
            >
              Web Order
            </li>

            <li>
              <a
                href="http://lifecare.wsweborder.com/StockAndSales/DistributorPages/Login4StockNSales.aspx"
                style={{ color: "#4d4d4d !important" }}
              >
                Stock & Sales
              </a>
            </li>
            <Link to={"/services"}>
              <li
                style={{
                  color:
                    location.pathname === "/services" ? "#F7AD00" : "#4d4d4d",
                }}
              >
                Services
              </li>{" "}
            </Link>
            {/* <Link to={"/product"}>
              <li
                style={{
                  color:
                    location.pathname === "/product" ? "#F7AD00" : "#4d4d4d",
                }}
              >
                Product
              </li>
            </Link> */}
            <Link to={"/career"}>
              <li
                style={{
                  color:
                    location.pathname === "/career" ? "#F7AD00" : "#4d4d4d",
                }}
              >
                Career
              </li>
            </Link>
            <Link to={"/contact"}>
              <li
                style={{
                  color:
                    location.pathname === "/contact" ? "#F7AD00" : "#4d4d4d",
                }}
              >
                Contact
              </li>
            </Link>
          </ul>
        </div>
        <div className="menu" onClick={showDrawer}>
          <HiOutlineMenu />
        </div>

        <Drawer
          placement="right"
          onClose={onClose}
          visible={visible}
          width={250}
        >
          <div className={"dr_content"}>
            <Link to={"/"} onClick={onClose}>
              <p
                style={{
                  color: location.pathname === "/" ? "#F7AD00" : "#4d4d4d",
                }}
              >
                Home
              </p>
            </Link>
            <Link to={"/aboutus"} onClick={onClose}>
              <p
                style={{
                  color:
                    location.pathname === "/aboutus" ? "#F7AD00" : "#4d4d4d",
                }}
              >
                About Us
              </p>
            </Link>
            <p onClick={handleShow}>Web Order</p>
            <a href="http://lifecare.wsweborder.com/StockAndSales/DistributorPages/Login4StockNSales.aspx">
              <p>Stock & Sales</p>
            </a>
            <Link to={"/services"} onClick={onClose}>
              <p
                style={{
                  color:
                    location.pathname === "/services" ? "#F7AD00" : "#4d4d4d",
                }}
              >
                Services
              </p>
            </Link>
            <Link to={"/product"} onClick={onClose}>
              <p
                style={{
                  color:
                    location.pathname === "/product" ? "#F7AD00" : "#4d4d4d",
                }}
              >
                Product
              </p>
            </Link>
            <Link to={"/career"} onClick={onClose}>
              <p
                style={{
                  color:
                    location.pathname === "/career" ? "#F7AD00" : "#4d4d4d",
                }}
              >
                Career
              </p>
            </Link>
            <Link to={"/contact"} onClick={onClose}>
              <p
                style={{
                  color:
                    location.pathname === "/contact" ? "#F7AD00" : "#4d4d4d",
                }}
              >
                Contact
              </p>
            </Link>
          </div>
        </Drawer>
      </nav>

      <div>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Login to Weborder</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Slider {...settings}>
              {banner.map((value) => (
                <div>
                  <div className={"slider row"}>
                    <div
                      className={"col-lg-12 col-md-12 col-sm-12 mobile-size "}
                    >
                      <a href="https://lifecare.retailorders.in">
                  
                        <div>
                          <img src={value.largeImageUrl} width="100%" />
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              ))}
                  {/* <div>
            <img src={Img7} width="100%" />
            </div>
               <div>
            <img src={Img8} width="100%" />
            </div>
            <div>
            <img src={Img9} width="100%" />
            </div>
            <div>
            <img src={Img10} width="100%" />
            </div>
            <div>
            <img src={Img11} width="100%" />
            </div>
            <div>
            <img src={Img12} width="100%" />
            </div>
            <div>
            <img src={Img13} width="100%" />
            </div>
            <div>
            <img src={Img14} width="100%" />
            </div> */}
            
            </Slider>
          </Modal.Body>
          <Modal.Footer>
            {/* <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleClose}>
              Save Changes
            </Button> */}

            <div className={"weborder-button"}>
              {/* <button>
                {" "}
               
                <a href="https://lifecare.retailorders.in/">Old Version</a>{" "}
              </button> */}
            </div>
            <div className={"weborder-button"}>
              <a href="https://lifecare.retailorders.in">
                <button>Close</button>
              </a>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};
export default Header;
