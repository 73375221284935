import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import Fade from 'react-reveal/Fade'
import Aboutus from './section/aboutus'
import Carsoul from './section/carsoul'
import Ourservices from './section/ourservices'
import Ourstrength from './section/ourstrength'

const Index = () => {
  useEffect(() => {

    window.scroll(0, 0)

  }, [])

  return (
    <div>
      <Helmet>
        <title>Lifecare Pharmaceuticals | Top Pharmaceautical wholesale distributors in India </title>
        <meta property="og:image" content="%PUBLIC_URL%/logo192.png" />
        <meta property="og:description" content="In 1999 emerged a reputed medical wholesale distributor which later grew up to be one of the top pharmaceutical distributor in india" />
        <meta name="theme-color" content="#000000" />
        <meta name="title" content="Lifecare Pharmaceuticals | Top Pharmaceautical wholesale distributors in India " />
        <meta name="description" content="Lifecarepharmaceuticals are India's leading wholesale pharma distributors providing all kinds of medicine from common to specialty drugs. Through our online platform you can order all kinds of medicines conveniently." />
        <meta name="keywords" content="Pharmaceautical wholesale distributors,pharmaceutical wholesalers, 
    wholesale pharma distributor,pharma distributors,pharmaceutical wholesale distributors,medicine wholesale distributor, 
    medicine distributorship,pharma distribution companies in Chennai, pharma import and export, speciality medicine wholesaler,
    speciality medicine distributor,speciality pharma distributor, specialty distributors pharmaceuticals, Pharmaceutical distribution Company, Pharmaceutical Distribution Companies in India, 
    Pharmaceutical Distributors in India,Top Pharmaceutical Distribution Companies in India,Pharmaceutical Distribution Companies in Tamil Nadu,Online Pharmacy India, Medicine Online, Purchase Medicine Online "></meta>
      </Helmet>

      <Carsoul />
      {/* <Ourstrength/> */}


      <Aboutus />

      <Ourservices />


    </div>
  )
}

export default Index;