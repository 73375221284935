import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import "../../app/product/product.css";
import { Data } from "../../app/product/data.js";
import { Table, Radio, Divider } from 'antd';
import TableComponent from './table';
import { AnimationOnScroll } from "react-animation-on-scroll";
import 'animate.css'








const Product = () => {

  useEffect(() => {
    
    window.scroll(0,0)
     
    }, [])
  const [search, setsearch] = useState('');
  const [filter, setfilter] = useState([]);


  
 

  


 

  useEffect(() => {
    if (search !== "") {
      const filteredData = Data.filter((e) =>
        e.name.toLowerCase().includes(search.toLowerCase())
      );
      setfilter(filteredData);
    } else {
      setfilter([]);
    }
  }, [search]);

  const data1 = filter.length !== 0 ? filter : Data;

  

  return (
    <>
      <div className={"product-bg"}>
        <Container>
          <div
            style={{
              display: "flex",
              height: "40vh",
              justifyContent: "center",
            }}
          >
            <div className={"product-heading"}>
            <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp">

              <p>
                <span>Home</span> | <span>Product</span>{" "}
              </p>
              <h1>DISTRIBUTOR LIST</h1>
            </AnimationOnScroll>
            </div>
          </div>
        </Container>
      </div>
     
      <Container>
      <div>
        <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp">

           <TableComponent />
        </AnimationOnScroll>
      
           
      </div>
      </Container>
   
      
    </>

  );
};
export default Product;






  


