import React from "react";
import { Container } from "react-bootstrap";
import "../../../app/contact/section/contactdetails.css";
import { BsTelephone } from "react-icons/bs";
import { FiMail } from "react-icons/fi";
import {GoLocation} from "react-icons/go";
import { AnimationOnScroll } from "react-animation-on-scroll";
import 'animate.css'

const Contactdetails = () => {
  return (
    <>
      <Container className={"py-5"}>
        <div className={"contactdetails-section"}>
          <div className={" row"}>
            <div className={" contactdetails-heading col-lg-12 py-5"}>
              <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp">

              <h1>
                CONTACT <span>US</span>{" "}
              </h1>
              </AnimationOnScroll>
            </div>
            <div className={" phone-section col-lg-4 col-md-4 col-sm-12 py-5"}>
              <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp">

              <div className={"cont-phone"}>
                <BsTelephone />
              </div>
              </AnimationOnScroll>
              <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp">

              <div className={"cont-phone-content py-3 "}>
                <p>
                  +91 89399 92609 | +91 89399 92610 <br /> +91 89399 92611 | +91
                  89399 92639
                </p>
              </div>
              </AnimationOnScroll>
            </div>
            <div className={" phone-section col-lg-4 col-md-4 col-sm-12  py-5"}>
              <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp">

              <div className={"cont-phone"}>
                <FiMail />
              </div>
              </AnimationOnScroll>
              <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp">

              <div className={"cont-phone-content py-3 "}>
                <p>Lifecare772000@yahoo.co.in</p>
                <p>info@lifecarepharma.com</p>
              </div>
              </AnimationOnScroll>
            </div>
            <div className={" phone-section col-lg-4 col-md-4 col-sm-12 py-5"}>
              <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp">

              <div className={"cont-phone"}>
                <GoLocation />
              </div>
              </AnimationOnScroll>
              <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp">

              <div className={"cont-phone-content py-3 "}>
                <p>
                  Lifecarepharma pvt ltd <br/> (Whole Sale Pharma Distributors) <br/> #16,
                  (Old No: 17) 8th Street, Gopalapuram,<br/> Chennai, Tamilnadu - 600086
                </p>
              </div>
              </AnimationOnScroll>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Contactdetails;
