import React, { useEffect, useState } from "react";
import { Data } from "./data";
import "../../app/product/product.css";
import { HiSearchCircle } from "react-icons/hi";
import { Pagination } from "@mui/material";
import { Container } from "react-bootstrap";

const Table = () => {
  const [search, setsearch] = useState("");
  const [numberOfItems, setnumberOfItems] = useState(5);
  const [slicedRows, setSlicedRows] = useState([]);
  const [filter, setfilter] = useState([]);

  const [page, setPage] = React.useState(1);
  const handleChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    if (search !== "") {
      const filteredData = Data.filter((e) =>
        e.name.toLowerCase().includes(search.toLowerCase())
      );
      setfilter(filteredData);
    } else {
      setfilter([]);
    }
  }, [search]);

  const data1 = filter.length !== 0 ? filter : Data;

  useEffect(() => {
    let i,
      j,
      temporary = [],
      chunk = numberOfItems;
    let slicedArray = [];
    for (i = 0, j = data1.length; i < j; i += chunk) {
      temporary = data1.slice(i, i + chunk);
      slicedArray.push(temporary);
    }
    setSlicedRows(slicedArray);
  }, [numberOfItems, data1]);

  const onSelectChange = (e) => {
    setnumberOfItems(e.target.value);
  };

  return (
    <div className={"py-5"}>
      <Container>
        <div className={"row"}>
          <div className={" col-lg-12 col-sm-12 col-sm-12 "}>
          <div className={"product-search "}>
            <HiSearchCircle className={"product-search-icon"} />
            <input
              placeholder="Type & Search Here..."
              type={"text"}
              value={search}
              onChange={(e) => setsearch(e.target.value)}
            />
          </div>
          </div>
        
        </div>
      </Container>

      <div className={"py-5"}>
        {(slicedRows.length !== 0 ? slicedRows[page - 1] : data1).map(
          (item, index) => (
            <div key={index} className={" row product-list"}>
              <div className={"col-lg-3 col-md-3 col-sm-12"}>
                <p><a href={item.URL1} target="_blank" >{item.name}</a></p>
              </div>
              <div className={"col-lg-3 col-md-3 col-sm-12"}>
                <p><a href={item.URL2}>{item.location}</a></p>
              </div>
              <div className={"col-lg-3 col-md-3 col-sm-12"}>
                <p><a href={item.URL3}>{item.pharmaname}</a></p>
              </div>
              <div className={"col-lg-3 col-md-3 col-sm-12"}>
                <p><a href={item.URL4}>{item.companyname}</a></p>
              </div>
              
            </div>
          )
        )}
      </div>
      <Container>
        <div className={"pagination"}>
          <div className={"page-dropdown"}>
            <label for="rowpage">Row Per Page</label>
            <select onChange={onSelectChange} name="rowpage" id="rowpage">
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={30}>30</option>
            </select>
          </div>
          <div className={"py-5"} style={{ textAlign: "left" }}>
            <Pagination
              count={slicedRows.length}
              page={page}
              onChange={handleChange}
            />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Table;
