import React, { useState } from "react";
import { Container } from "react-bootstrap";
import "../../../app/about/section/headerimage.css";
import Abimg from "../../../../asset/about-us.png";
import "animate.css";
import { AnimationOnScroll } from "react-animation-on-scroll";

const Aboutusindex = () => {
  const [showAll, setshowAll] = useState(false);

  return (
    <section>
      <div className={"aboutus1-bg"}>
        <Container>
          <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInLeft">
            <div style={{ display: "flex", height: "40vh" }}>
              <div className={"adoutus-heading"}>
                <p>
                  <span>Home</span> | <span>About Us</span>
                </p>
                <h5>ABOUT US</h5>
              </div>
            </div>
          </AnimationOnScroll>
        </Container>
      </div>

      <Container>
        <div className={"row"}>
          <div className={"col-lg-5"}>
            <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp">
              <div className={"ab-image"}>
                <img src={Abimg} width="90%" />
              </div>
            </AnimationOnScroll>
          </div>

          <div className={"col-lg-7"}>
            <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp">
              <p className={showAll ? "ab-content" : "ab-content-hide"}>
                Founded in 1999, LifeCare Pharmaceuticals has become a leading
                pharmaceutical distributor in Chennai, consistently ranking
                among the top two in the region. We have built a strong
                reputation for reliable service, which sets us apart in the
                industry. From humble beginnings, we have grown significantly,
                with an expanding portfolio of pharmaceutical companies and a
                steadily increasing customer base. Our distribution network
                covers all areas of Chennai and its surrounding regions.
                <br />
                <br />
                Our strength lies in our comprehensive range of products from
                750 pharmaceutical companies and their divisions, meeting nearly
                85% of a chemist’s requirements. We proudly serve over 3,000
                customers, including major corporate hospitals, government
                institutions, and retailers in Chennai.
                <br />
                <br />
                We maintain state-of-the-art infrastructure, including 24/7
                walk-in cold rooms for cold chain products. These products are
                distributed to hospitals and retailers by a dedicated team,
                ensuring proper cold chain management. Our sales team,
                consisting of 40 personnel and 10 delivery specialists focused
                on institutional clients, is committed to providing timely
                service, even on Sundays and holidays.
                <br />
                <br />
                LifeCare also extends services to the up-country market through
                a dedicated team that collects and fulfills retail orders,
                ensuring that even patients in remote areas have access to
                essential medicines that may not be available locally.
                <br />
                <br />
                At LifeCare Pharmaceuticals, the patient is our top priority.
                Every aspect of our operations—from procurement to logistics—is
                centered on ensuring the best possible service to our ultimate
                customer: the patient. This commitment to excellence drives our
                ongoing dedication to the healthcare community.
              </p>
              <div className={"adout-cont"}>
                <button
                  className={"ab-button"}
                  onClick={() => setshowAll((prev) => !prev)}
                >
                  Read More
                </button>
              </div>
            </AnimationOnScroll>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Aboutusindex;
