import React from "react";
import { Container } from "react-bootstrap";
import "../../about/section/ourteam.css";
import Ourteamimg from "../../../../asset/ourteam/ourteam2.png";
import Devendran from '../../../../asset/ourteam/2.png';
import Edward from "../../../../asset/ourteam/3.png";
import Chandra from "../../../../asset/ourteam/4.png";
import Antony from "../../../../asset/ourteam/5.png";
import Manoharan from "../../../../asset/ourteam/6.png";
import 'animate.css';
import { AnimationOnScroll } from "react-animation-on-scroll";

const Ourteam = () => {
  return (
    <section>
      <Container className={"py-5"}>
        <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp">
        {/* <div className={"row"}>
          <div
            className={
              "  ourteam-heading col-lg-12 d-flex justify-content-center py-5 "
            }
          >
            <h1>
              OUR <span>TEAM</span>
            </h1>
          </div>
          <div className={"col-lg-3 col-md-6 col-sm-6 col-6 py-4"}>
            <div className={"ourteam-image"}>
              <img src={Edward} alt='General Manager of lifecarepharma ' />

              <div className={"ourteam-names"}>
                <h3>MR. JONATHAN EDWARD</h3>
                <p>General Manager</p>
              </div>
              <div className="h-img-content">
                <p>
                General Manager incharge of Business Operations. 
                logistics, labour control and inventory management.
                </p>
              </div>
            </div>
          </div>
          <div className={"col-lg-3 col-md-6 col-sm-6 col-6 py-4 " }>
            <div className={"ourteam-image"}>
              <img src={Ourteamimg} alt='Admin manager Manager of lifecarepharma' />
              <div className={"ourteam-names"}>
                <h3>MR. SEKHAR</h3>
                <p>Admin Manager</p>
              </div>
              <div className="h-img-content">
                <p>
                  Designate as Admin Manager in charge for all Company's
                  business transaction, accounts and reconnaissance. Pivotal
                  Manager coordinating with all departments.
                </p>
              </div>
            </div>
          </div>
          <div className={"col-lg-3 col-md-6 col-sm-6 col-6 py-4"}>
            <div className={"ourteam-image"}>
              <img src={Devendran} alt="Sales manager of lifecarepharma " />
              <div className={"ourteam-names"}>
                <h3>MR. DEVENDRAN</h3>
                <p>Sales Manager</p>
              </div>
              <div className="h-img-content">
                <p>
                Designate as Sales Manager, Institutional sales and overall sale management. 
                In charge for entire day to day sales and recon work.
                </p>
              </div>
            </div>
          </div>
          
          <div className={"col-lg-3 col-md-6 col-sm-6 col-6 py-4"}>
            <div className={"ourteam-image"}>
              <img src={Chandra} alt="BDM of lifecarepharma" />
              <div className={"ourteam-names"}>
                <h3>MR. CHANDRA MOHAN</h3>
                <p>Business Development Manager</p>
              </div>
              <div className="h-img-content">
                <p>
                Business Development Manager in charge of sales developmental 
                activities related to salesmen control, target planning & achievement.
                </p>
              </div>
            </div>
          </div>
          <div className={"col-lg-3 col-md-6 col-sm-6 col-6 py-4"}>
            <div className={"ourteam-image"}>
              <img src={Antony} alt="Manager Purchase of lifecarepharma" />
              <div className={"ourteam-names"}>
                <h3>MR. ANTONY KUMAR</h3>
                <p>Manager, Purchase</p>
              </div>
              <div className="h-img-content">
                <p>
                Manager, Purchase. Incharge for all purchases and reconciliation 
                works related to stock maintanence, product movement.
                </p>
              </div>
            </div>
          </div>
          <div className={"col-lg-3 col-md-6 col-sm-6 col-6 py-4"}>
            <div className={"ourteam-image"}>
              <img src={Manoharan} alt="Relationship Executive of lifecarepharma" />
              <div className={"ourteam-names"}>
                <h3>MR. SR. MANOHARAN</h3>
                <p>Relationship Executive</p>
              </div>
              <div className="h-img-content">
                <p>
                Relationship executive. Managing and retaining of personal relationship with Pharma 
                sales personnel, C&f's and all important external clients & customers. 
                In-charge for all domestic logistics and maintenance works.
                </p>
              </div>
            </div>
          </div>
        </div> */}
        </AnimationOnScroll>
       
      </Container>
    </section>
  );
};

export default Ourteam;
