import React, { useRef, useState } from "react";
import { Container, Form } from "react-bootstrap";
import "../../app/career/career.css";
import { AnimationOnScroll } from "react-animation-on-scroll";
import "animate.css";
import Axios from "axios";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import emailjs from '@emailjs/browser';
import { Helmet } from "react-helmet";

function Career() {
  const [data, setData] = useState({
    name: null,
    email: null,
    subject: null,
    message: null,
  });
  const [resume, setResume] = useState("");
  let navigate = useNavigate();

  // const sendEmail = (e) => {
  //     e.preventDefault();
  //
  //     const fData = new FormData();
  //     fData.append('resume',resume);
  //     Axios.post('http://localhost:8000/api/save', fData).then(res => {
  //         console.log(res.data)
  //         console.log(fData)
  //     }).catch(err => {
  //         console.log(err)
  //     })
  //
  // }

  // const sendEmail = (e) => {
  //   e.preventDefault();
  //   if (
  //     data.name !== null &&
  //     data.email !== null &&
  //     data.subject !== null &&
  //     data.message !== null &&
  //     data.resume !== null
  //   ) {
  //     const fData = new FormData();

  //     fData.append("data", JSON.stringify(data));
  //     fData.append("resume", resume);

  //     Axios.post("http://localhost:8000/api/career", fData).then((res) => {
  //       if (res.data == "Email is Already is Taken Please use New email") {
  //         swal("Email is Already is Taken Please use New email", "ERROR");
  //         console.log(res.data);
  //       } else {
  //         swal("Success", "success");
  //         navigate("/");
  //       }
  //     });
  //   } else {
  //   }
  // };

   const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_34iu6ve', 'template_840n45i', form.current, 'tB4a9jn0ooucu1yfJ')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  };

  return (
    <>
    <Helmet>
      
    </Helmet>
      <div className={"career-bg"}>
        <Container>
          <div className={"row career-bg-align "}>
            <div className={"career-breadcrums col-lg-7 col-md-12 col-sm-12 "}>
              <AnimationOnScroll
                animateOnce={true}
                animateIn="animate__fadeInUp"
              >
                <p>
                  <span>Home</span> | <span>Career</span>
                </p>
                <h1>Upload Your Resume Here</h1>
              </AnimationOnScroll>
            </div>

            <div className={" col-lg-5 col-md-12 col-sm-12 "}>
              <AnimationOnScroll animateOnce={true} animateIn="animate__zoomIn">
                <div className={"career-form"}>
                  <Form ref={form} onSubmit={sendEmail}>
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label className={"f-lable"}>Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter your name"
                        name="user_name"
                       
                      />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label className={"f-lable"}>
                        Email Address
                      </Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Enter your email"
                        name="user_email"
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicSubject">
                      <Form.Label className={"f-lable"}>Subject</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter message subject"
                        name="user_subject"
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicMessage">
                      <Form.Label className={"f-lable"}>Message </Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="Write a message here"
                        name="user_message"
                        
                      />
                    </Form.Group>
                    {/* <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label className={"f-lable"}>
                        Upload Resume
                      </Form.Label>
                      <Form.Control
                        type="file"
                        onChange={(text) => setResume(text.target.files[0])}
                      />
                    </Form.Group> */}

                    <div className={"form-button"}>
                      <button type="submit">SUBMIT</button>
                    </div>
                  </Form>
                </div>
              </AnimationOnScroll>
            </div>
            {/*<form onSubmit={sendEmail} >*/}
            {/*    <input  type='file' onChange={text => setResume(text.target.files[0])}/>*/}
            {/*    <input type='submit'/>*/}
            {/*</form>*/}
          </div>
        </Container>
      </div>
    </>
  );
}

export default Career;
