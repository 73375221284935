import React, { useState } from "react";
import { Button, Container, Modal, ModalHeader } from "react-bootstrap";
import "../../../app/about/section/aboutdirector.css";

import Ramesh from "../../../app/about/section/aboutdirector/ramesh.js";
import Hari from "../../../app/about/section/aboutdirector/hari.js";
import 'animate.css';
import { AnimationOnScroll } from "react-animation-on-scroll";

const Aboutdirector = () => {
  return (
    <section className="about-director py-5 ">
      <Container>
        <div className={"row  "}>
          <div
            className={
              " director-heading col-lg-12 d-flex justify-content-center py-5"
            }
          >
            <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp">
            <h1>
              ABOUT <span>DIRECTORS</span>{" "}
            </h1>
            </AnimationOnScroll>
           
          </div>
          <div className={"col-lg-6 col-md-6 col-sm-12 py-5 "}>
            <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInLeft">

            <Ramesh />
            </AnimationOnScroll>
          </div>
          <div className={"col-lg-6 col-md-6 col-sm-12 py-5"}>
            <AnimationOnScroll animateOnce={true}  animateIn="animate__fadeInRight">

            <Hari />
            </AnimationOnScroll>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Aboutdirector;
