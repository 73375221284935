import Index from "./components/app/home/index.js";
import Header from "./components/shared/header.js";
import "../src/app.css";
import Footer from "./components/shared/footer";
import Aboutuspage from "../src/components/app/about/aboutus-index.js";
import { Routes, Route, BrowserRouter, Link } from "react-router-dom";
import Services from "./components/app/services/services.js";
import Product from "./components/app/product/product.js";
import Career from "./components/app/career/career.js";
import Contact from "./components/app/contact/contact.js";
import { RiWhatsappFill } from "react-icons/ri";
import { HiUserCircle } from "react-icons/hi";
import { AiOutlineSearch } from "react-icons/ai";
import { BsThreeDotsVertical } from "react-icons/bs";
import { AiOutlineCloseCircle } from "react-icons/ai";

import { useEffect, useState } from "react";
import ReactGa from 'react-ga';

function App() {
  const [isChatboxOpen, setIsChatboxOpen] = useState(false);

  // useEffect(() => {

  //   ReactGa.initialize('G-RWE2JHC3M1');
  //   ReactGa.pageview(window.location.pathname + window.location.search);
  //   ReactGa.pageview('/app');
  
  // }, [])
  return (
    <div className="App">
      {/* <a href="https://api.whatsapp.com/send?phone=7397341330&text=Hi%20how%20are%20you?">
            <div className={"callbt-para"}>
              <p>Click Here to Chat</p>
            </div>
          </a> */}
          <div className={"call-button-align"}>

    
        <div className="callbutton">
          {isChatboxOpen ? (
            <AiOutlineCloseCircle
              className="callbutton-icon"
              onClick={() => {
                setIsChatboxOpen(false);
              }}
            />
          ) : (
            <RiWhatsappFill
              className="callbutton-icon"
              onClick={() => {
                setIsChatboxOpen(true);
              }}
            />
          )}
        </div>
      
          </div>
        <div
          className={`chat-box-container ${
            isChatboxOpen ? "callbutton-open" : "callbutton-close"
          } `}
        >
          <div className={"chat-box-header"}>
            <div className={"chat-box-header-inside"}>
              <h3>Lifecarepharma</h3>
              <div>
                <AiOutlineSearch className={"chat-box-inside-icon2"} />
                <BsThreeDotsVertical className={"chat-box-inside-icon2"} />
              </div>
            </div>
          </div>
          <div className={"chat-box-header2"}>
            <a href="https://api.whatsapp.com/send?phone=+918939992610&text=Hello,%20I'd like some%20information about%20Lifecare Pharma">
              <div className={"chat-box-inside2"}>
                <HiUserCircle className={"chat-box-inside-icon"} />
                <div>
                  <h3>Sales</h3>
                  <p>Have any Query pls ask</p>
                </div>
              </div>
            </a>
          </div>
          <div className={"chat-box-header2"}>
            <a href="https://api.whatsapp.com/send?phone=+919600269084&text=Hello,%20I'd like some%20information about%20Lifecare Pharma">
              <div className={"chat-box-inside2"}>
                <HiUserCircle className={"chat-box-inside-icon"} />
                <div>
                  <h3>Stock Enquiry</h3>
                  <p>Have any Query pls ask</p>
                </div>
              </div>
            </a>
          </div>
          <div className={"chat-box-header2"}>
            <a href="https://api.whatsapp.com/send?phone=+918939992639&text=Hello,%20I'd like some%20information about%20Lifecare Pharma">
              <div className={"chat-box-inside2"}>
                <HiUserCircle className={"chat-box-inside-icon"} />
                <div>
                  <h3>Feedback</h3>
                  <p>Have any Query pls ask</p>
                </div>
              </div>
            </a>
          </div>
          <div className={"chat-box-header2"}>
            <a href="https://api.whatsapp.com/send?phone=+918939996241&text=Hello,%20I'd like some%20information about%20Lifecare Pharma">
              <div className={"chat-box-inside2"}>
                <HiUserCircle className={"chat-box-inside-icon"} />
                <div>
                  <h3>Accounts</h3>
                  <p>Have any Query pls ask</p>
                </div>
              </div>
            </a>
          </div>
        </div>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="contact" element={<Contact />} />
          <Route path="career" element={<Career />} />
          <Route path="product" element={<Product />} />
          <Route path="services" element={<Services />} />
          <Route path="aboutus" element={<Aboutuspage />} />
          <Route path="/" Index element={<Index />} />
        </Routes>

        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
