import React, {  useRef, useState, useEffect } from "react";
import "../../home/section/carsoul.css";
import Car from "../../../../asset/newcar.png";
import Car2 from "../../../../asset/newcar2.png";
import Carous1 from "../../../../asset/bgbanner2.jpg";
import Carous2 from "../../../../asset/bgbanner3.jpg";
import Carous3 from "../../../../asset/bgbanner4.jpg";
import Mobilecar from "../../../../asset/mobilebgbanner.jpg";
import Mobilecar2 from "../../../../asset/mobilebgbanner2.jpg";
import Mobilecar3 from "../../../../asset/mobilebgbanner3.jpg";
// import Car3 from "../../../../asset/newcarsvg.svg";
import { Button, Carousel } from "antd";
import { Container } from "react-bootstrap";
import Slider from "react-slick";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { Fade } from "react-reveal";
import { useMediaQuery } from "@mui/material";
import axios from 'axios';
import { Modal } from "react-bootstrap";
import Img1 from '../../../../asset/wobanners/1.jfif';
import Img2 from '../../../../asset/wobanners/2.jfif';
import Img3 from '../../../../asset/wobanners/4.jfif';
import Img5 from '../../../../asset/wobanners/5.jpg';
import Img6 from '../../../../asset/wobanners/6.jfif';
import Img7 from '../../../../asset/wobanners/7.jpeg';
import Img8 from '../../../../asset/wobanners/8.jpeg';
import Img9 from '../../../../asset/wobanners/9.jpeg';
import Img10 from '../../../../asset/wobanners/10.jpeg';
import Img11 from '../../../../asset/wobanners/11.jpeg';
import Img12 from '../../../../asset/wobanners/12.jpeg';
import Img13 from '../../../../asset/wobanners/13.jpeg';
import Img14 from '../../../../asset/wobanners/14.jpeg';
import Img15 from '../../../../asset/wobanners/15.jpeg';
import Img16 from '../../../../asset/wobanners/16.jpeg';
import Img17 from '../../../../asset/wobanners/17.jpeg';


const settings2 = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  cssEase: "linear",
};

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
};
const Carsoul = () => {
  const ref = useRef();

  const is770pxBelow = useMediaQuery("(max-width:770px)");  
  const is430pxBelow = useMediaQuery("(max-width:430px)");
  
const [banner, setbanner] = useState([])
useEffect(() => {
  axios
  .get("https://api.lifecareweborder.com/api/get/lifecarebanners")
  .then((res) => {
    setbanner(res.data);
  })
  .catch((err) => {
    console.log(err);
  });
}, [])


const [show, setShow] = useState(false);

const handleClose = () => setShow(false);
const handleShow = () => setShow(true);

 const [banner2, setbanner2] = useState([]);
  useEffect(() => {
    axios
    .get("https://api.lifecareweborder.com/api/get/banners")
    .then((res) => {
      setbanner2(res.data);
    })
    .catch((err) => {
      console.log(err);
    });
  }, [])

  return (
    <>
      <div className="background">
        <Slider {...settings} ref={ref}>
          {
            banner.map(value => (

              <img src={Carous1} width="100%" onClick={handleShow} style={{cursor:"pointer"}} />
            //   <div>
            //   <div className={"slider row"}>
            //     <Fade top>
            //       <div className={"col-lg-12 col-md-12 col-sm-12 mobile-size "}>
                
                 
            //         {/* {is770pxBelow ? (
            //           <img src={value.smallImageUrl} width="100%" />
            //         ) : (
            //           <img src={value.largeImageUrl} width="100%" />
            //         )} */}
            //      <div>
                  
            //         {/* <img src={value.largeImageUrl} width="100%" onClick={handleShow} style={{cursor:"pointer"}} /> */}
                    
            //      </div>

               
               
    
            //     </div>
                
            //     </Fade>
            //   </div>
            // </div>
            ))
          }
         
         <img src={Carous1} width="100%" onClick={handleShow} style={{cursor:"pointer"}} />
         <img src={Carous2} width="100%" onClick={handleShow} style={{cursor:"pointer"}} />
         <img src={Carous3} width="100%" onClick={handleShow} style={{cursor:"pointer"}} />
        </Slider>
        <div className={"slider-button"}>
          <button
            onClick={() => {
              ref.current.slickPrev();
            }}
            className={"left"}
          >
            <IoIosArrowBack />
          </button>
          <button className={"right"} onClick={() => ref.current.slickNext()}>
            <IoIosArrowForward />
          </button>
        </div>
      </div>


      <div>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Login to Weborder</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Slider {...settings2}>
              {banner2.map((value) => (
                <div>
                  <div className={"slider row"}>
                    <div
                      className={"col-lg-12 col-md-12 col-sm-12 mobile-size "}
                    >
                      
                  
                        <div>
                          <img src={value.largeImageUrl} width="100%"  />
                        </div>
                    
                    </div>
                  </div>
                </div>
              ))}
              {/* <div>
                 <img src={Img15} width="100%" />
            </div>
            <div>
                 <img src={Img16} width="100%" />
            </div>
            <div>
                 <img src={Img17} width="100%" />
            </div>
            <div>
                 <img src={Img7} width="100%" />
            </div>
            <div>
                 <img src={Img8} width="100%" />
            </div>
            <div>
            <img src={Img9} width="100%" />
            </div>
            <div>
            <img src={Img10} width="100%" />
            </div>
            <div>
            <img src={Img11} width="100%" />
            </div>
            <div>
            <img src={Img12} width="100%" />
            </div>
            <div>
            <img src={Img13} width="100%" />
            </div>
            <div>
            <img src={Img14} width="100%" />
            
            </div> */}
            </Slider>
          </Modal.Body>
          <Modal.Footer>
            {/* <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleClose}>
              Save Changes
            </Button> */}

            <div className={"weborder-button"}>
              {/* <button>
                {" "}
               
                <a href="https://lifecare.retailorders.in/">Old Version</a>{" "}
              </button> */}
            </div>
            <div className={"weborder-button"}>
              <a href="https://lifecare.retailorders.in">
                <button>Close</button>
              </a>
            </div>
          </Modal.Footer>
        </Modal>
      </div>

    </>
  );
};
export default Carsoul;
