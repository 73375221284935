import React, { useState } from "react";
import { Container } from "react-bootstrap";
import "../shared/footer.css";
import Wlogo from "../../asset/wlogo.png";
import {
  FaFacebookSquare,
  FaTwitterSquare,
  FaGooglePlusSquare,
  FaInstagramSquare,
} from "react-icons/fa";
import { VscDebugBreakpointLog } from "react-icons/vsc";
import { GiRotaryPhone } from "react-icons/gi";
import { RiPhoneFill, RiMailFill, RiMapPin2Fill } from "react-icons/ri";
import { MdLocationOn } from "react-icons/md";
import { Link } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import Slider from "react-slick/lib/slider";
import Webimg from "../../asset/weborder.jpg";

const Footer = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
  };
  return (
    <>
      {/* Helloe jhfjj */}
      <div className={"bg-color"}>
        <Container>
          <div className={"row footer"}>
            <div className={"col-lg-4 col-md-6 col-sm-12 footer-colum1 mt-5 "}>
              <h5>Company</h5>
              <img src={Wlogo} />
              <p>
                Lifecare Pharmaceuticals today is a leading <br />
                Pharmaceutical Distributor in Chennai..
              </p>
              <div className={"social-icons"}>
                <FaFacebookSquare className="icons" />
                <FaTwitterSquare className="icons" />
                <FaGooglePlusSquare className="icons" />
                <FaInstagramSquare className="icons" />
              </div>
            </div>
            <div className={"col-lg-2 col-md-3 col-sm-6 footer-colum2 mt-5  "}>
              <h5>Quick Links</h5>
              <ul>
                <Link to={"/"}>
                  <li>
                    <VscDebugBreakpointLog className="icons2" />
                    <span style={{ color: "white", cursor:"pointer" }}>Home</span>
                  </li>
                </Link>
                <Link to={"/aboutus"}>
                  <li>
                    <VscDebugBreakpointLog className="icons2" />
                    <span style={{ color: "white", cursor:"pointer" }}>About Us</span>
                  </li>
                </Link>

                <li onClick={handleShow}>
                  <VscDebugBreakpointLog className="icons2" />
                 <span style={{color:"white", cursor:"pointer" }}>Web Order</span> 
                </li>
                <a href="http://lifecare.wsweborder.com/StockAndSales/DistributorPages/Login4StockNSales.aspx">

                <li>
                  <VscDebugBreakpointLog className="icons2" />
                 <span style={{color:"white", cursor:"pointer"}}>Stock & Sales</span> 
                </li>
                </a>
              </ul>
            </div>
            <div className={"col-lg-2 col-md-3 col-sm-6 footer-colum3 mt-5  "}>
              <h5>Quick Links</h5>
              <ul>
                <Link to={"/services"}>
                <li>
                  <VscDebugBreakpointLog className="icons2" />
                  <span style={{color:"white", cursor:"pointer"}}>Services </span>
                </li>
                </Link>
                <Link to={"/product"}>
                  <li>
                    <VscDebugBreakpointLog className="icons2" />
                    <span style={{ color: "white", cursor:"pointer" }}>Product</span>
                  </li>
                </Link>
                <Link to={"/career"}>
                <li>
                  <VscDebugBreakpointLog className="icons2" />
                 <span style={{color:"white", cursor:"pointer"}}>Career</span> 
                </li>
                </Link>
                <Link to={"/contact"}>
                <li>
                  <VscDebugBreakpointLog className="icons2" />
                 <span style={{color:"white", cursor:"pointer"}}> Contact Us</span> 
                </li>
                </Link>
              </ul>
            </div>
            <div className={"col-lg-4 col-md-12 col-sm-12 footer-colum4 mt-5 "}>
              <h5>Contact Us</h5>
              <div className={"contact-us1"}>
                <GiRotaryPhone className="contact-icons" />
                <p>044-28111099 / 399 / 499 / 699 / 799</p>
              </div>

              <div className={"contact-us2"}>
                <RiPhoneFill className="contact-icons" />
                <p>
                  +91 89399 92609 | +91 89399 92610 <br /> +91 89399 92611 | +91
                  89399 92639
                </p>
              </div>

              <div className={"contact-us2"}>
                <RiMailFill className="contact-icons" />
                <p>Lifecare772000@yahoo.co.in</p>
                <p>| info@lifecarepharma.com</p>
              </div>

              <div className={"contact-us2"}>
                <RiMapPin2Fill className="contact-icons1" />

                <p>
                  <span>
                    Lifecarepharma pvt ltd (Whole Sale Pharma Distributors)
                  </span>
                  <br />
                  New No : 14 & 16, 8th street, Gopalapuram, Chennai-600086, Tamilnadu
                </p>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <Container>
        <div className={"footer-rights row"}>
          <div className={" footer-rights-content col-lg-3 col-md-3 col-sm-12"}>
            <h5>© 2022 Lifecare . All Rights Reserved</h5>
          </div>
          <div className={" footer-rights-border  col-lg-6 col-md-6 col-sm-12"}>
            <hr></hr>
          </div>
          <div className={" footer-left-content col-lg-3 col-md-3 col-sm-12"}>
            {/* <h5>
              Developed by <span>MERCY IT CORP</span>
            </h5> */}
          </div>
        </div>
      </Container>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Login to Weborder</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Slider {...settings}>
            <div>
              <img src={Webimg} width="100%" />
            </div>
            <div>
              <img src={Webimg} width="100%" />
            </div>
          </Slider>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleClose}>
              Save Changes
            </Button> */}

          <div className={"weborder-button"}>
            {/* <button>
              {" "}
              <a href="https://lifecare.retailorders.in/">
                Close
              </a>{" "}
            </button> */}
          </div>
          <div className={"weborder-button"}>
          <a href="https://lifecare.retailorders.in/">
            <button>
              {" "}
           Close{" "}
            </button>
            </a>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Footer;
