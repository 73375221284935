import React from "react";
import { Container } from "react-bootstrap";
import "../../home/section/aboutus.css";
import AboutUs from "../../../../asset/about-us.png";
import Fade from "react-reveal/Fade";

import "animate.css";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { Link } from "react-router-dom";

const Aboutus = () => {
  return (
    <section>
     
      <div className={"aboutus-bg  "}>
        <Container>
          <div className={"row "}>
            <div className={"col-lg-5 aboutus-img "}>
              <div>
                  <img src={AboutUs} />
              </div>
                
            </div>

            <div className={"col-lg-7 aboutus-content "}>
              <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInUp">
              <h1>
                About <span>Life Care</span>{" "}
              </h1>
              <p>
                Started in 1999 Lifecare Pharmaceuticals today is a leading
                Pharmaceutical Distributor in Chennai. Being One amongst 'Top
                Two' Pharmaceutical distributor in Chennai, Lifecare
                Pharmaceuticals has its own reputation. Service has been the key
                word which makes Lifecare Pharmaceuticals different from others.
                From a humble beginning Lifecare Pharmaceuticals has risen with
                increasing number of Companies under the umbrella and an ever
                incresaing customer base. Our coverage reaches every nook and
                corner of Chennai City and its peripheries.
              </p>
              <Link to={"/aboutus"} className={"readmore-ab-button"}>
                <button className={"readmore-ab"}>READ MORE</button>
              </Link>
              </AnimationOnScroll>
            
            </div>
          </div>
        </Container>
      </div>
    </section>

    // <section className='about-container-1' >

    // </section>
  );
};

export default Aboutus;
