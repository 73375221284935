import React, { useState } from "react";
import { Button, Container, Modal, ModalHeader } from "react-bootstrap";
import "../../../../app/about/section/aboutdirector.css";
import Profile from "../../../../../asset/profile-img.png";
import { AiOutlineCloseCircle } from "react-icons/ai";

import { BsArrowRightCircle } from "react-icons/bs";

const Aboutdirector = (props) => {
  const [show, Setshow] = useState(false);

  const handleClose = () => Setshow(false);
  const handleShow = () => Setshow(true);

  return (
    <>
      <Container>
        
      
         
           
            <div className={'director-img'} onClick={handleShow} >
                <img src={Profile} alt='Director for lifecarepharma'   />
                <div className={"director-name"}>
                <div>
                  <h3>MR.HARIHARAN RAMESH</h3>
                  <p>Director</p>
                </div>
                <div className={"director-icon"}>
                  <BsArrowRightCircle />
                </div>
              </div>
            </div>

            <Modal
              show={show}
              onHide={handleClose}
              {...props}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              style={{width:'100%'}}
            >
              <div className={"row model-box-image "}>
                <div className=" col-lg-12 d-flex justify-content-end ">
                  <AiOutlineCloseCircle onClick={handleClose} className={'model-close-icon'} />
                </div>
                <div className={"col-lg-4"}>
                  <img src={Profile} width="100%" />
                </div>
                <div className={" col-lg-8"}>
                  <Modal.Body className={"model-box"}>
                    <p>
                    Mr. Hariharan Ramesh has learnt all the nuances 
                    of the business at a very young age. Passionate, 
                    progressive and focused he will be taking the Organizational 
                    growth to higher levels with his innovative entrepreneur skills. 
                    Changes are already visible with many of his decisions are on 
                    right path of success for the organization
                    </p>
                  </Modal.Body>
                </div>
               
              </div>
            </Modal>
          
        
      </Container>
    </>
  );
};

export default Aboutdirector;
