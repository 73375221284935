import React from 'react'
import { Container } from 'react-bootstrap';
import '../../../app/contact/section/location.css'
import { AnimationOnScroll } from 'react-animation-on-scroll';
import 'animate.css';

 const Location = () => {
  return (
    <>
    <Container className={"py-5"}>
        <div className={"location-section   row"} >
            <div className={"col-lg-12 col-md-12 col-sm-12 py-5"}>

<AnimationOnScroll animateOnce={true} animateIn="animate__zoomIn">

        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.7984420369826!2d80.25295961473635!3d13.048498016716923!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a526639c0000001%3A0x866979b92dbed814!2sLIFECARE%20PHARMA%20PVT%20LTD!5e0!3m2!1sen!2sin!4v1649741609926!5m2!1sen!2sin" width={"100%"} height={300} style={{border: 0}} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
</AnimationOnScroll>
            </div>
        </div>

    </Container>

    </>
  )
}

export default Location;