import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet';
import Headerimage from '../../app/about/section/headerimage.js'
import Aboutdirector from './section/aboutdirector';
import Gallery from './section/gallery.js';
import Ourteam from './section/ourteam';


const Aboutusindex = () => {
  useEffect(() => {

    window.scroll(0, 0)

  }, [])

  return (

    <>
      <section>
        <Helmet>
          <title>About LifecarePharma | Best Wholesale Pharma Distributors</title>
          <meta name="title" content="About LifecarePharma | Best Wholesale Pharma Distributors" />

          <meta name="description" content="We are a pharmaceutical distribution company who offer a wide range of high-quality medications and medical supplies. With a focus on customer satisfaction, we provide fast and reliable delivery services to healthcare providers across the country" />
          <meta name="keywords" content="Pharmaceautical wholesale distributors,pharmaceutical wholesalers, 
    wholesale pharma distributor,pharma distributors,pharmaceutical wholesale distributors,medicine wholesale distributor, 
    medicine distributorship,pharma distribution companies in Chennai, pharma import and export, speciality medicine wholesaler,
    speciality medicine distributor,speciality pharma distributor, specialty distributors pharmaceuticals, Pharmaceutical distribution Company, Pharmaceutical Distribution Companies in India, 
    Pharmaceutical Distributors in India,Top Pharmaceutical Distribution Companies in India,Pharmaceutical Distribution Companies in Tamil Nadu,Online Pharmacy India, Medicine Online, Purchase Medicine Online "></meta>
        </Helmet>
        <Headerimage />
        <Aboutdirector />
        <Ourteam />
        <Gallery />





      </section>

    </>


  )
}

export default Aboutusindex;