export const Data = [
  {
   "name": "18 Herbs Organic",
   "location": "6Th Skin Pharmaceuticals Pvt Ltd",
   "pharmaname": "A.F.D.Pharma.",
   "companyname": "A.S.V.Laboratories Pvt Ltd",
   "URL1": "https://18herbs.com/",
   "URL2": "https://www.zaubacorp.com/company/6th-Skin-Pharmaceuticals-Private-Limited/U24233TN2013PTC091841",
   "URL3": "https://www.afdil.com/",
   "URL4": "https://www.lifecarepharma.com/",
  },
  {
   "name": "Aadhavan Pharma",
   "location": "Aakash Pharmaceuticals",
   "pharmaname": "Aarogya",
   "companyname": "Aarux Pharmaceuticals Pvt Ltd",
   "URL1": "",
   "URL2": "https://www.hugedomains.com/domain_profile.cfm?d=akashpharma.com",
   "URL3": "https://www.aarogyasetu.gov.in/",
   "URL4": "https://aaruxpharma.com/",
  },
  {
   "name": "Aarven Pharma",
   "location": "Abbot Boot Product",
   "pharmaname": "Abbott - Nicholas",
   "companyname": "Abbott India Ltd(Ii)",
   "URL1": "https://www.arvenilac.com.tr/en/",
   "URL2": "https://www.abbottnutrition.com/",
   "URL3": "https://www.engineering.cornell.edu/faculty-directory/nicholas-l-abbot",
   "URL4": "https://www.abbott.co.in/",

  },
  {
   "name": "Abbott India Ltd(Neuroscience)",
   "location": "Abn- Drug",
   "pharmaname": "Abott Diagnostics.",
   "companyname": "Abott India Ltd",
   "URL1": "https://www.abbott.in/products/therapy-areas.neuroscience.html",
   "URL2": "https://www.medindia.net/drugs/manufacturers/abn.htm",
   "URL3": "https://www.diagnostics.abbott/us/en/home.html",
   "URL4": "https://www.abbott.co.in/",

  },
  {
   "name": "Abott Specialty Care",
   "location": "Acinus Pharma",
   "pharmaname": "Acme Pharma.",
   "companyname": "Adhvaith Enterprises",
   "URL1": "https://www.abbott.in/products/business-areas.pharmaceuticals-abbott-india-limited.html",
   "URL2": "https://www.zaubacorp.com/company/ACINUS-PHARMA-AND-RESEARCH-PRIVATE-LIMITED/U74900PY2014PTC002910",
   "URL3": "http://acmepharmaindia.com/",
   "URL4": "https://www.sulekha.com/adhvaith-enterprises-thiruverkadu-chennai-contact-address",

  },
  {
   "name": "Adith",
   "location": "Adlib Biologicals",
   "pharmaname": "Adonis",
   "companyname": "Adroit Biomed (P) Ltd",
   "URL1": "",
   "URL2": "https://www.indiamart.com/adlib-biologicals/",
   "URL3": "http://acmepharmaindia.com/",
   "URL4": "https://adroitbiomed.com/",

  },
  {
   "name": "Aeon Biologicals Pvt Ltd",
   "location": "Aeon Formulation Pvt Ltd",
   "pharmaname": "Afiya Pharmaceuticals Pvt Ltd",
   "companyname": "Agk Enterprises",
   "URL1": "https://aeonf.com/",
   "URL2": "https://aeonf.com/",
   "URL3": "https://www.zaubacorp.com/company/AFIYA-PHARMACEUTICALS-PRIVATE-LIMITED/U24231TN2006PTC060193",
   "URL4": "",

  },
  {
   "name": "Agna Biotech",
   "location": "Aice Health",
   "pharmaname": "Ailaron Pharma",
   "companyname": "Aileron Life Sciences Pvt.Ltd",
   "URL1": "https://www.tradeindia.com/agna-biotech-8889976/",
   "URL2": "https://www.aicehealthcare.com/",
   "URL3": "https://aileronrx.com/",
   "URL4": "https://www.indiamart.com/aileron-life-sciences-private-limited/profile.html",

  },
  {
   "name": "Aimil Pharmaceuticals Ltd",
   "location": "Ajanta (Cdc)",
   "pharmaname": "Ajantha Pharma",
   "companyname": "Ak Medics",
   "URL1": "https://www.aimilpharmaceuticals.com/",
   "URL2": "http://www.ajantapharma.com/",
   "URL3": "http://www.ajantapharma.com/",
   "URL4": "https://www.akmedics.net/"

   ,
  },
  {
   "name": "Akesiss Pharma",
   "location": "Aksaj Drugs",
   "pharmaname": "Aksigen Hospital Care",
   "companyname": "Akumentis Healthcare Ltd",
   "URL1": "https://akesiss.com/",
   "URL2": "https://www.insiderbiz.in/company/AKSAJ-DRUGS-AND-SURGICALS-PRIVATE-LIMITED",
   "URL3": "http://aksigen.com/",
   "URL4": "https://akumentishealthcare.com/",

  },
  {
   "name": "Akums Drugs",
   "location": "Akums Drugs& Pharmaceuticalns Ltd",
   "pharmaname": "Alacris Healthcare",
   "companyname": "Alaska Life Sciences",
   "URL1": "https://www.akums.in/",
   "URL2": "https://www.akums.in/",
   "URL3": "https://www.zaubacorp.com/company/ALACRIS-HEALTHCARE-PRIVATE-LIMITED/U24230TZ2015PTC021532/",
   "URL4": "https://www.zaubacorp.com/company/ALASKA-LIFESCIENCES-PRIVATE-LIMITED/U74900BR2010PTC015926"

  },
  {
   "name": "Albert David Ltd",
   "location": "Alchem Pharma",
   "pharmaname": "Alcon Laboratories(Eed)",
   "companyname": "Alcon Laboratories(Gd)",
   "URL1": "https://www.albertdavidindia.com/",
   "URL2": "http://www.alcheminternational.com/",
   "URL3": "https://www.alcon.com/",
   "URL4": "https://www.alcon.com/"

  },
  {
   "name": "Alekm Neurosciences",
   "location": "Alembic Pharma",
   "pharmaname": "Alenmeds Healthcare Pvt Ltd",
   "companyname": "Alexpen Remedies",
   "URL1": "https://www.alkemlabs.com/neuro-psychiatry-(cns).php",
   "URL2": "https://alembicpharmaceuticals.com/",
   "URL3": "https://www.thecompanycheck.com/company/alenmeds-health-care-private-limited/U85110KA2015PTC082037",
   "URL4": "https://www.indiamart.com/alex-pen-remedies-chennai/about-us.html"

  },
  {
   "name": "Al-Kausar Herbal Hospitals(P) Ltd",
   "location": "Alkem",
   "pharmaname": "Alkem Bergen.",
   "companyname": "Alkem Cosmokem",
   "URL1": "https://herbalhospitalstore.com/",
   "URL2": "https://www.alkemlabs.com/",
   "URL3": "https://www.alkemlabs.com/",
   "URL4": "http://atorhealthcare.com/distributors.asp?division=ALKEM%20COSMOKEM"

  },
  {
   "name": "Alkem Cytomed",
   "location": "Alkem Cytomed Viro",
   "pharmaname": "Alkem Laboratories",
   "companyname": "Alkem Laboratories Ltd",
   "URL1": "https://www.alkemlabs.com/oncology.php",
   "URL2": "https://www.alkemlabs.com/",
   "URL3": "https://www.alkemlabs.com/",
   "URL4": "https://www.alkemlabs.com/"

  },
  {
   "name": "Alkem Laboratories-Ii",
   "location": "Alkem Pentacare",
   "pharmaname": "Alkem Ulticare",
   "companyname": "Alleregon",
   "URL1": "https://www.alkemlabs.com/",
   "URL2": "https://www.alkemlabs.com/",
   "URL3": "https://www.alkemlabs.com/",
   "URL4": "https://www.allergan.co.in/hi-in/home"

  },
  {
   "name": "Alniche",
   "location": "Altiuz Pharma Care",
   "pharmaname": "Alvio Pharmaceuticals",
   "companyname": "Ambar Healthcare",
   "URL1": "https://www.alniche.com/",
   "URL2": "https://altiuz-pharma-care.business.site/?utm_source=gmb&utm_medium=referral",
   "URL3": "https://alviopharma.com/",
   "URL4": "http://amberhealthcare.in/"

  },
  {
   "name": "Amdis Health Sciences",
   "location": "Amla",
   "pharmaname": "Amra Remedies",
   "companyname": "Amrutanjan Health Care Ltd",
   "URL1": "http://amdis.in/",
   "URL2": "",
   "URL3": "https://www.zaubacorp.com/company/AMRA-REMEDIES-LIMITED/U24239MH2011PLC213956",
   "URL4": "https://www.amrutanjan.com/"

  },
  {
   "name": "Amyntas Health Care",
   "location": "Anav Pharmaceuticals",
   "pharmaname": "Anthem Biopharma",
   "companyname": "Aod Pharma",
   "URL1": "https://amyntas-health-care.business.site/",
   "URL2": "https://www.arnavpharmachem.com/",
   "URL3": "https://anthembio.com/",
   "URL4": "https://www.justdial.com/jdmart/Solan/Aod-Pharma-Industrial-Area-Hpsidc-Village-Katha-Baddi/9999P1792-1792-121124093812-P7R2_BZDET/catalogue"

  },
  {
   "name": "Apex Pharmaceuticals",
   "location": "Apikos Pharma",
   "pharmaname": "Apple Bio Medics Ltd",
   "companyname": "Arches Pharmaceuticals",
   "URL1": "https://www.apexlab.com/",
   "URL2": "https://www.apikospharma.com/",
   "URL3": "http://applebiomedics.net/",
   "URL4": "http://www.archespharma.com/index.html"

  },
  {
   "name": "Ardor Pharma",
   "location": "Areete Life Sciences Pvt Ltd",
   "pharmaname": "Aretaeus Life Pharmaceuticals",
   "companyname": "Arien Labs",
   "URL1": "https://pharma.industry-report.net/ardor-drugs-pvt-ltd/",
   "URL2": "https://areetelifescience.com/",
   "URL3": "https://www.zaubacorp.com/company/ARETAEUS-PHARMACEUTICALS-PRIVATE-LIMITED/U36912TN2009PTC071309#:~:text=Aretaeus%20Pharmaceuticals%20Private%20Limited%20is,paid%20up%20capital%20is%20Rs.",
   "URL4": "https://www.zaubacorp.com/company/ARIEN-LABS-PRIVATE-LIMITED/U72200KA2015PTC080911"

  },
  {
   "name": "Aristo Pharmaceuticals Pvt Ltd",
   "location": "Ark Lifesciences.",
   "pharmaname": "Arka Vital",
   "companyname": "Arkray Piramal",
   "URL1": "https://www.aristopharma.co.in/",
   "URL2": "https://www.indiamart.com/ark-life-science-ltd/aboutus.html",
   "URL3": "https://www.zaubacorp.com/company/ARKA-VITAL-SCIENCE-PRIVATE-LIMITED/U74999TN2016PTC113236",
   "URL4": "https://www.arkray.co.in/english/index.html"

  },
  {
   "name": "Asctel Pharmaceuticals",
   "location": "Asira Marketing Pvt Ltd",
   "pharmaname": "Aspa Marketing",
   "companyname": "Astley Life Sciences",
   "URL1": "https://www.zaubacorp.com/company/ASCTEL-PHARMACEUTICALS-PRIVATE-LIMITED/U51599TN2016PTC111579",
   "URL2": "https://www.zaubacorp.com/company/ASIRA-MARKETING-PRIVATE-LIMITED/U52300TN2010PTC075189",
   "URL3": "https://www.zaubacorp.com/company/ASPA-MARKETING-PRIVATE-LIMITED/U51396TN2009PTC073316",
   "URL4": "https://www.astleylifesciences.com/"

  },
  {
   "name": "Astra Idl",
   "location": "Astrum Health Care",
   "pharmaname": "Atbro",
   "companyname": "Aube Life Sciences",
   "URL1": "http://astraidl.com/",
   "URL2": "https://astrumhealthcare.in/",
   "URL3": "https://atbro.in/",
   "URL4": "http://aubelifesciences.com/"
  },
  {
   "name": "Auctus Labs",
   "location": "Auikland Biotech",
   "pharmaname": "Aurochem Laboratories",
   "companyname": "Auroma Pharmaceuticals",
   "URL1": "https://www.zaubacorp.com/company/AUCTUS-LABS-PRIVATE-LIMITED/U85100TN2013PTC093644",
   "URL2": "https://www.justdial.com/jdmart/Chennai/Auikland-Bio-Tech-Behind-Jaya-Medicals-Royapuram/044PXX44-XX44-110721103600-T7V2_BZDET/catalogue",
   "URL3": "https://www.aurochem.global/",
   "URL4": ""

  },
  {
   "name": "Aurz Pharmaceticals",
   "location": "Austrak Divya",
   "pharmaname": "Avaance Life Sciences",
   "companyname": "Aventis Pharma Ltd",
   "URL1": "https://www.zaubacorp.com/company/AURZ-PHARMACEUTICAL-PRIVATE-LIMITED/U24231KA2007PTC044290",
   "URL2": "https://www.tnmeds.com/AUSTRAK-DIVYA-m1652.aspx?sort=p.model&order=DESC",
   "URL3": "https://www.alspl.com/",
   "URL4": "https://www.sanofiindialtd.com/"

  },
  {
   "name": "Avinash",
   "location": "Avitas",
   "pharmaname": "Avitas Life Sciences",
   "companyname": "Avitech.",
   "URL1": "",
   "URL2": "https://www.avitas.com/?v=c86ee0d9d7ed",
   "URL3": "https://avitaslifesciences.com/",
   "URL4": "https://avtechsoftwareservices.co.in/"

  },
  {
   "name": "Avn Ayurveda Formulation",
   "location": "Avt Natural Products Ltd",
   "pharmaname": "Axe",
   "companyname": "Ayngaran Pharma Distributos",
   "URL1": "http://www.avnayurveda.co.in/",
   "URL2": "https://www.avtnatural.com/",
   "URL3": "",
   "URL4": "https://www.indiamart.com/ayngaran-pharma"

  },

  {
   "name": "Azeveston Healthcare Pvt Ltd",
   "location": "Azoe Pharmaceutical India Pvt Ltd",
   "pharmaname": "Aztomax Biotech",
   "companyname": "Bafna Pharmaceuticals Ltd",
   "URL1": "http://azveston.com/",
   "URL2": "https://www.zaubacorp.com/company/ZOE-PHARMACEUTICALS-PVT-LTD/U24239MH1985PTC037360",
  },
  {
   "name": "Bajaj Pharmaceuticals",
   "location": "Bausch &Lomb",
   "pharmaname": "Bayer Diagnostics.",
   "companyname": "Bayer Pharma"
  },
  {
   "name": "Bayer Pharmaceuticals-Ii",
   "location": "Bdr Pharmaceuticals",
   "pharmaname": "Beacon Pharma",
   "companyname": "Beacon Pharmaa"
  },
  {
   "name": "Becton Dickinson India Pvt Ltd",
   "location": "Beejan Pharma",
   "pharmaname": "Bemaxx Pharmacuticals",
   "companyname": "Benz Pharma"
  },
  {
   "name": "Besins Healthcare India P.Ltd",
   "location": "Beumed Healthcare",
   "pharmaname": "Bhakti",
   "companyname": "Bharat Serum"
  },
  {
   "name": "Bharath Biologicals",
   "location": "Bharath Biologicals",
   "pharmaname": "Bio Felexer",
   "companyname": "Bio Pharma Lines"
  },
  {
   "name": "Bio Sena Life Sciences",
   "location": "Bio Zenica Health",
   "pharmaname": "Biochem -Ii",
   "companyname": "Biochem. Labotaories."
  },
  {
   "name": "Biocon Limited",
   "location": "Bio-Crop",
   "pharmaname": "Biocute Life Care",
   "companyname": "Biocutis Life Sciences"
  },
  {
   "name": "Biofloris",
   "location": "Biogen",
   "pharmaname": "Biogeniccs Pharmaceuticals",
   "companyname": "Biological E"
  },
  {
   "name": "Biomaaks Drugs India Pvt Ltd",
   "location": "Biomiicron Pharmaceuticals",
   "pharmaname": "Bion",
   "companyname": "Biopharm Care"
  },
  {
   "name": "Biophonix Healthcare",
   "location": "Biospectrum Pharmaceuticals",
   "pharmaname": "Biotech Trading",
   "companyname": "Biotics Lab"
  },
  {
   "name": "Biovitamins",
   "location": "Blisson Mediplus",
   "pharmaname": "Bloom Biocare",
   "companyname": "Blue Cross"
  },
  {
   "name": "Bluray",
   "location": "Bms",
   "pharmaname": "Boehringer",
   "companyname": "Boharras Inc"
  },
  {
   "name": "Bond Health Care",
   "location": "Branko",
   "pharmaname": "Brighters Pharma",
   "companyname": "Brinton Pharmaceuticals Ltd"
  },
  {
   "name": "Brio Bliss Life Science",
   "location": "Bristol-Myers Squibb.",
   "pharmaname": "British Biologicals",
   "companyname": "Cachet Pharmaceuticals"
  },
  {
   "name": "Cadila",
   "location": "Cadila Derma.",
   "pharmaname": "Cadila Health Care",
   "companyname": "Cadila Magna."
  },
  {
   "name": "Cadila Pharma",
   "location": "Caduceus",
   "pharmaname": "Canixa Life Sciences Pvt Ltd",
   "companyname": "Captab Biotec"
  },
  {
   "name": "Cardeon Formulations",
   "location": "Cardio Diabetic",
   "pharmaname": "Care Maxim Health Care",
   "companyname": "Casomed Pharmaceuticals"
  },
  {
   "name": "Casproh Pharma",
   "location": "Celagenex Research",
   "pharmaname": "Celon Labs",
   "companyname": "Centaur Pharma."
  },
  {
   "name": "Chakra Speacality",
   "location": "Charak",
   "pharmaname": "Charan Laboratories",
   "companyname": "Chemech Ii"
  },
  {
   "name": "Chevron Life",
   "location": "Chrijes Pharmaceuticals",
   "pharmaname": "Cipla Ltd",
   "companyname": "Circadian"
  },
  {
   "name": "Circadian Pharma",
   "location": "Clamyn",
   "pharmaname": "Claris Lifesciences.",
   "companyname": "Cloud 7 Pharma"
  },
  {
   "name": "Cognyzon Pharmaceuticals",
   "location": "Cohrem Pharma",
   "pharmaname": "Colgate",
   "companyname": "Comed Pharmaceuticals Ltd"
  },
  {
   "name": "Commonwelthcare",
   "location": "Conarcs",
   "pharmaname": "Corana Remedies Pvt Ltd",
   "companyname": "Corem Pharmaceuticals"
  },
  {
   "name": "Cosmas Pharmacis Ltd",
   "location": "Cosset",
   "pharmaname": "Crescent Formulations Pvt Ltd",
   "companyname": "Cresha Lifesciences"
  },
  {
   "name": "Cura Remedies",
   "location": "Curatas Pharmaceuticals",
   "pharmaname": "Curatio Health Care (I) P Ltd",
   "companyname": "Cutem Dermacare"
  },
  {
   "name": "Cu-V-Kar Genetics",
   "location": "D Lab",
   "pharmaname": "Daeiou Pharmaceuticals",
   "companyname": "Daris Biocare"
  },
  {
   "name": "Daris Nushine",
   "location": "Dedeepya Pharma",
   "pharmaname": "Dee Gradibus Life Care",
   "companyname": "Deekay Life Science"
  },
  {
   "name": "Delcure Lifesciences Ltd",
   "location": "Derein Formulations",
   "pharmaname": "Dexbio Pharma Pvt Ltd",
   "companyname": "Dexbio Pharma Pvt Ltd"
  },
  {
   "name": "Dkt India",
   "location": "D-Labs",
   "pharmaname": "Dr Moni Pharmaceuticals",
   "companyname": "Dr Ortho"
  },
  {
   "name": "Dr Reddy'S",
   "location": "Dr Reddy'S (Grandera)",
   "pharmaname": "Dr Reddy'S (Grandera)",
   "companyname": "Dr.John'S Lab Healthcare Pvt Ltd"
  },
  {
   "name": "Dr.Jrk'S Siddha Research& Pharmaceuticals Pvt Ltd",
   "location": "Dr.Morepen",
   "pharmaname": "Dr.Reddys Futura",
   "companyname": "Dream Pharma"
  },
  {
   "name": "Ducare",
   "location": "Dunamis Life Sciences",
   "pharmaname": "East India Pharmaceutical Works Limited",
   "companyname": "East West"
  },
  {
   "name": "Ebullient Pharma",
   "location": "Edg Pharmaceuticals India Limited",
   "pharmaname": "Edric Pharamceuticals",
   "companyname": "Elbrit Life Sciences Pvt Ltd"
  },
  {
   "name": "Elder Adevents",
   "location": "Elder Eldermis Div.",
   "pharmaname": "Elder Elvista Division",
   "companyname": "Elder Hospinex."
  },
  {
   "name": "Elder Majestex.",
   "location": "Elder Pharma (A) Div.",
   "pharmaname": "Elder Pharma (B) Gyne",
   "companyname": "Elder Pharma B Cp Div."
  },
  {
   "name": "Elder(Consumer)",
   "location": "Eli Lilly",
   "pharmaname": "Elixia Life Sciences",
   "companyname": "Els Pharmaceuticals"
  },
  {
   "name": "Elsai Pharma",
   "location": "El-Shaddai.",
   "pharmaname": "Elvent Pharmaceuticals",
   "companyname": "Embark Pharmaceuticals"
  },
  {
   "name": "Emcure Criante",
   "location": "Emcure Emcutix",
   "pharmaname": "Emcure Neurosciences",
   "companyname": "Emcure Pharma (Nugron)"
  },
  {
   "name": "Emcure Pharma Ltd",
   "location": "Emcure Sustinex",
   "pharmaname": "Emcure Vertex",
   "companyname": "Emcure Xennea"
  },
  {
   "name": "Emcure Xinnex",
   "location": "Emcure Zemcure",
   "pharmaname": "Emdrix Pharmaceuticals",
   "companyname": "Encor"
  },
  {
   "name": "Entod Pharmaceuticals",
   "location": "Enzeame Health Care",
   "pharmaname": "Ergos Pharma",
   "companyname": "Eris Pharma"
  },
  {
   "name": "Eris Pharma-Ii",
   "location": "Erotas Associates",
   "pharmaname": "Esmatrix Lifesciences Pvt Ltd",
   "companyname": "Essfel Pharma"
  },
  {
   "name": "Essjohn Pharma",
   "location": "Ethicare Remedies",
   "pharmaname": "Everest Pharama",
   "companyname": "Eysys Pharmaceuticals"
  },
  {
   "name": "Eysys Pharmaceuticals Pvt Ltd",
   "location": "F.D.C.Ltd.",
   "pharmaname": "Famo Biotech",
   "companyname": "Femura Pharmaceuticals"
  },
  {
   "name": "Ferring Pharmaceuticals",
   "location": "Fertile Pharmaceuticals",
   "pharmaname": "Fervent Life Sciences",
   "companyname": "Fine Formulations"
  },
  {
   "name": "Fiziks Life Science",
   "location": "Flaring",
   "pharmaname": "Floris Skin Care",
   "companyname": "Flowers Pharmaceuticals"
  },
  {
   "name": "Forecare Pharmaceuticals Pvt Ltd",
   "location": "Fountil Life Sciences Pvt Ltd",
   "pharmaname": "Fourrts India Pvt Ltd",
   "companyname": "Fourrts(India)Laboratories Ltd"
  },
  {
   "name": "Fourts India Lab P Ltd(Specia)",
   "location": "Fovea Pharmaceuticals",
   "pharmaname": "Franco Indian -I",
   "companyname": "Franco Indian-(Diabetic)"
  },
  {
   "name": "Fresenius Kabi",
   "location": "Fresenius Kabi",
   "pharmaname": "Fresenius Kabi India",
   "companyname": "Fulford"
  },
  {
   "name": "Fusion Health Care",
   "location": "G.V.Healthcare",
   "pharmaname": "Galaxy",
   "companyname": "Galaxy Pharma"
  },
  {
   "name": "Galaxy Pharmaceuticals",
   "location": "Galderma Pharma",
   "pharmaname": "Galien Glitz Pharma",
   "companyname": "Gary"
  },
  {
   "name": "Gates Byby Pharma",
   "location": "Gel Tech Pharma",
   "pharmaname": "Gelnova Lab",
   "companyname": "Gen-Biotec Pvt Ltd"
  },
  {
   "name": "Generic",
   "location": "Genetics Life Science",
   "pharmaname": "Geneux Science Laboratories",
   "companyname": "Genmedic"
  },
  {
   "name": "Gennova Biopharmaceuticals",
   "location": "Geno Pharma",
   "pharmaname": "German Gyno",
   "companyname": "German Remedies"
  },
  {
   "name": "German Remedies (Topcare)",
   "location": "German Respicare",
   "pharmaname": "Gini Inc",
   "companyname": "Gkm New Pharma"
  },
  {
   "name": "Gladstone Pharma India (P) Ltd",
   "location": "Glaxo Asclepius-Team-Iii",
   "pharmaname": "Glaxo Derma Ett",
   "companyname": "Glaxo Entro Plus Team-1"
  },
  {
   "name": "Glaxo Team-4",
   "location": "Glaxo Team-6",
   "pharmaname": "Glaxo Team-8",
   "companyname": "Glaxo Team-Ii-Derma"
  },
  {
   "name": "Glb Pharmacy",
   "location": "Glen Mark",
   "pharmaname": "Glenmark ( Majesta )",
   "companyname": "Glenmark (Integrace)"
  },
  {
   "name": "Glenmark Pharma",
   "location": "Glenmark(Gracewell Spl)",
   "pharmaname": "Glorimed Pharmaceuticals",
   "companyname": "Gnosis"
  },
  {
   "name": "Golgi Usa Research Lab",
   "location": "Good Health",
   "pharmaname": "Good Health Supplement Pvt Ltd",
   "companyname": "Goodman Pharmaceuticals"
  },
  {
   "name": "Goodman Pharmaceuticals",
   "location": "Goodres Pharmaceuticals",
   "pharmaname": "Grafeio Healthcare Pvt Ltd",
   "companyname": "Grandix Pharma"
  },
  {
   "name": "Grandixx Adventa.",
   "location": "Grandixx Zest",
   "pharmaname": "Gray Anon India",
   "companyname": "Greenmoon Biochem"
  },
  {
   "name": "Gromax Health Care Pvt Ltd",
   "location": "Group Pharmaceuticals Ltd",
   "pharmaname": "Gufic Biosciences Ltd",
   "companyname": "H&H Pharmaceutica Pvt Ltd"
  },
  {
   "name": "Haaldane Laboratories Pvt Ltd",
   "location": "Hagnous Bioceuticals (P)Ltd",
   "pharmaname": "Harshan Pharmaceuticals",
   "companyname": "Harson Lab Pvt Ltd"
  },
  {
   "name": "Heal India Pharmaceuticals Llp",
   "location": "Health N U Therapeutics",
   "pharmaname": "Helbek Pharma",
   "companyname": "Heptagon"
  },
  {
   "name": "Heptakings Impex",
   "location": "Hetero 1",
   "pharmaname": "Hetero 2",
   "companyname": "Hexagon Nutrition Pvt Ltd"
  },
  {
   "name": "Hibiscus Pharmaceticals Pvt Ltd",
   "location": "Hicare Pharma",
   "pharmaname": "Higlance Think Gel",
   "companyname": "Himalaya Aurvedic"
  },
  {
   "name": "Himalaya Zandra",
   "location": "Himalaya Zenith",
   "pharmaname": "Hitech Formulation",
   "companyname": "Hll Lifecare Limited"
  },
  {
   "name": "Hll Lifecare Ltd",
   "location": "Hori",
   "pharmaname": "Human Biologicals",
   "companyname": "Hygen Healthcare Pvt Ltd"
  },
  {
   "name": "Icaraus Health Care Pvt Ltd.",
   "location": "Icon Impetus",
   "pharmaname": "Icon Life Sciences",
   "companyname": "Icpa Health Product"
  },
  {
   "name": "Ideal Lifesciences",
   "location": "Ikon Remedies",
   "pharmaname": "Illban Remedies",
   "companyname": "I-Med Pharmaceuticals"
  },
  {
   "name": "Incite",
   "location": "Ind Swift Ltd",
   "pharmaname": "Indchemie Health Specialities Pvt Ltd",
   "companyname": "Indi Pharma"
  },
  {
   "name": "Indiabulls",
   "location": "Indo Asian Herbal Laboratories",
   "pharmaname": "Indo -French Healthcare Ltd",
   "companyname": "Indoco Healthcare"
  },
  {
   "name": "Indoco Remidies Eterna",
   "location": "Indofrench",
   "pharmaname": "Indo-French",
   "companyname": "Infivis Life Care"
  },
  {
   "name": "Inga Laboratories P.Ltd",
   "location": "Ingrez Pharmachems Pvt Ltd",
   "pharmaname": "Innokon Laboratories Pvt Ltd",
   "companyname": "Innova Lifesciences Pvt Ltd"
  },
  {
   "name": "Innovate Pharma",
   "location": "Innovcare",
   "pharmaname": "Inspire India Pharmaceuticals P.Ltd",
   "companyname": "Intaa Health Care"
  },
  {
   "name": "Intas",
   "location": "Intas Pharmaceuticals (Viva)",
   "pharmaname": "Integris Healthcare",
   "companyname": "Intergral Lifesciences"
  },
  {
   "name": "Invento Life Science",
   "location": "Invida India Pvt Ltd.",
   "pharmaname": "Invision Medi Sciences",
   "companyname": "Inzpera Health Sciences"
  },
  {
   "name": "Ipca",
   "location": "Ipca Activa",
   "pharmaname": "Isis Health Care",
   "companyname": "J.K Print Packs"
  },
  {
   "name": "Jacsims",
   "location": "Jadale Lifesciences",
   "pharmaname": "Jagdale Healthcare",
   "companyname": "Jagsonpal Pharmaceuticals Ltd"
  },
  {
   "name": "Jasvas Biologicals Pharma",
   "location": "Jawa Laboratories",
   "pharmaname": "Jaywin Remedies Pvt Ltd",
   "companyname": "Jenburkt Pharmaceticals"
  },
  {
   "name": "Jenya Healthcare",
   "location": "Jk Life Science",
   "pharmaname": "Johnson & Johnson",
   "companyname": "Johnson & Johnson(Health Care)"
  },
  {
   "name": "Jubilant Life Sciences",
   "location": "Juggat Pharma",
   "pharmaname": "K&K Products",
   "companyname": "Kadsun"
  },
  {
   "name": "Kaiser Pharmaceuticals",
   "location": "Kaizen Laboratories Pvt Ltd",
   "pharmaname": "Kalpa Healthcare Pvt Ltd",
   "companyname": "Kamasutra"
  },
  {
   "name": "Kanchan Pharma",
   "location": "Karlin Pharmaceuticals",
   "pharmaname": "Karnataka Antibiotic",
   "companyname": "Kasen Pharma"
  },
  {
   "name": "Katra Phytochem",
   "location": "Kavys Pharma",
   "pharmaname": "Kesini Pharmaceticals",
   "companyname": "Keystone Pharma"
  },
  {
   "name": "Khandelwal Labotories Pvt Ltd",
   "location": "Khanna Agency",
   "pharmaname": "Kinedex Healthcare Pvt Ltd",
   "companyname": "Kinesis Pharmaceuticals P Ltd"
  },
  {
   "name": "Kiosence Health Care Pvt Ltd",
   "location": "Klarvoyant Biogenics (P) Ltd",
   "pharmaname": "Kms Pharma",
   "companyname": "Kniss Laboratories P Ltd"
  },
  {
   "name": "Ko Pharmaceuticals",
   "location": "Koye Pharmaceuticals",
   "pharmaname": "Krisan Pharmaceuticals Pvt Ltd",
   "companyname": "Kunnath Pharma."
  },
  {
   "name": "Kurizon",
   "location": "La Life Sciences (P) Ltd",
   "pharmaname": "La Pharmaceuticals",
   "companyname": "La Pristine Bioceuticals Pvt Ltd"
  },
  {
   "name": "La Renon Healthcare Pvt Ltd",
   "location": "La Vincita",
   "pharmaname": "Lactonova India",
   "companyname": "Laomai Therapeutics"
  },
  {
   "name": "Larion",
   "location": "Lee Pharmaceuticals",
   "pharmaname": "Lg Life Sciences India Pvt Ltd",
   "companyname": "Life Gain Pharma"
  },
  {
   "name": "Life Medicare & Biotech Pvt Lt",
   "location": "Lifezen Healthcare",
   "pharmaname": "Lilly",
   "companyname": "Limra Healthcare"
  },
  {
   "name": "Linux Laboratories Pvt Ltd",
   "location": "Lloyd Healthcare Pvt Ltd",
   "pharmaname": "Lunatus Healthcare",
   "companyname": "Lupin"
  },
  {
   "name": "Lupin Blue Eye",
   "location": "Lupin Diabetic Division",
   "pharmaname": "Lupin Limited(Pinnacle)",
   "companyname": "Lupin Ltd (Sangam)"
  },
  {
   "name": "Lupin-Pinnacle Cvn",
   "location": "Luxe Derma & Cosmetics",
   "pharmaname": "Lyka Healthcare",
   "companyname": "M.I.Enterprises"
  },
  {
   "name": "M.S.D.",
   "location": "Maanav Herbal Ltd.",
   "pharmaname": "Maaxmus Life Sciences",
   "companyname": "Macleods (Macphar)"
  },
  {
   "name": "Macleods (Pro Care Aht)",
   "location": "Macleods (Pro Care Cv)",
   "pharmaname": "Macleods (Tb Care)",
   "companyname": "Macleods Pharmaceuticals Ltd"
  },
  {
   "name": "Macleods(Aeromac)",
   "location": "Macleods(Gencare Osteva)",
   "pharmaname": "Macleods(Gencare)",
   "companyname": "Macrohem"
  },
  {
   "name": "Magathi Pharmaa",
   "location": "Makarios Life Sciences",
   "pharmaname": "Mallur Flora & Hospitality Pvt Ltd",
   "companyname": "Malva Healthcare"
  },
  {
   "name": "Manesh Pharmaceuticals",
   "location": "Mankind Pharma (Dicovery)",
   "pharmaname": "Mankind Pharma Ltd",
   "companyname": "Mankind Pharma(Lifestar)"
  },
  {
   "name": "Mano Pharma",
   "location": "Mano Pharma ( Classic )",
   "pharmaname": "Mano Pharma ( Harmony )",
   "companyname": "Mano Pharma(Symphony)"
  },
  {
   "name": "Maruthua Pharma",
   "location": "Mas Life Science",
   "pharmaname": "Max Distributors,",
   "companyname": "Maxum Pharma"
  },
  {
   "name": "Mc Medi Bio Life Science",
   "location": "Mdc Pharmaceuticals Ptd",
   "pharmaname": "Med Manor Organics Pvt Ltd",
   "companyname": "Medcros"
  },
  {
   "name": "Meddoxx Pharma",
   "location": "Medi Biotech",
   "pharmaname": "Medi Sales Ibinide",
   "companyname": "Medi Surge Health Care."
  },
  {
   "name": "Medicamen Biotech Ltd",
   "location": "Medicaments",
   "pharmaname": "Mediez Pharma Pvt Ltd",
   "companyname": "Mediguard Remedies"
  },
  {
   "name": "Medinn Bell Herbel",
   "location": "Medisis Pharma",
   "pharmaname": "Medispan",
   "companyname": "Medispan Ltd"
  },
  {
   "name": "Medly",
   "location": "Mednith Biotech",
   "pharmaname": "Medopharm",
   "companyname": "Medula Life Sciences"
  },
  {
   "name": "Medzest Life Sciences",
   "location": "Medzest Life Sciences",
   "pharmaname": "Medzone Pharmaceuticals Pvt Ltd",
   "companyname": "Meracus Pharmaceuticals"
  },
  {
   "name": "Merck (Cm Care )",
   "location": "Merck Gen.Divison",
   "pharmaname": "Merck Ltd Chc Div.",
   "companyname": "Merck Min Divi."
  },
  {
   "name": "Mercury",
   "location": "Mercury Laboratories Ltd",
   "pharmaname": "Merind",
   "companyname": "Merven Pharmaceuticals"
  },
  {
   "name": "Metrich Life Science Llp",
   "location": "Meyer Organics Pvt Ltd",
   "pharmaname": "Mhs Pharmaceuticals",
   "companyname": "Micro Bal Pharma"
  },
  {
   "name": "Micro Bal Servitace",
   "location": "Micro Brown & Burk",
   "pharmaname": "Micro Cardicare",
   "companyname": "Micro Carsyon"
  },
  {
   "name": "Micro Carsyon Diabetic",
   "location": "Micro Diabetic Division",
   "pharmaname": "Micro Glyduz.",
   "companyname": "Micro Gratia."
  },
  {
   "name": "Micro Healthcare Ltd",
   "location": "Micro Labs Ltd.,",
   "pharmaname": "Micro Nova",
   "companyname": "Micro Synapse"
  },
  {
   "name": "Micro Task Force",
   "location": "Micro Vision",
   "pharmaname": "Micro-Erasma",
   "companyname": "Midas Pharma"
  },
  {
   "name": "Millennium Herbal Care Ltd",
   "location": "Minol Pharmaceuticals",
   "pharmaname": "Mint Pharmaceuticals Pvt Ltd",
   "companyname": "Mirth Phar,Aceuticals"
  },
  {
   "name": "Mits",
   "location": "Mizaph Enterprises",
   "pharmaname": "Mmc Healthcare Limited",
   "companyname": "Mmc Healthcare Ltd"
  },
  {
   "name": "Mmc Healthcare Ltd",
   "location": "Mms Arrow Pharma",
   "pharmaname": "Modern Medisciences Pvt Ltd",
   "companyname": "Modi Mundi Pharma"
  },
  {
   "name": "Molekule India Pvt Ltd",
   "location": "Moods",
   "pharmaname": "Moon Drugs",
   "companyname": "Morepen Laboratories Ltd"
  },
  {
   "name": "Morris Pristine",
   "location": "Mova Pharmaceuticals Pvt Ltd",
   "pharmaname": "Msn Labopratories Ltd",
   "companyname": "Msp Pharma"
  },
  {
   "name": "Munot Pharmaceuticals",
   "location": "Mylan",
   "pharmaname": "Nanoceut Therapeurics Pvt Ltd",
   "companyname": "Narang Plastics"
  },
  {
   "name": "Natco Cardia",
   "location": "Natco Oncology",
   "pharmaname": "Natco Pharma",
   "companyname": "Natural Organic"
  },
  {
   "name": "Natureflo Healthcare Pvt Ltd",
   "location": "Nebulae Pharmaceticals",
   "pharmaname": "Nemesis Biotech",
   "companyname": "Neolab Pharmaceuticals"
  },
  {
   "name": "Neon Laboratories.",
   "location": "Neoveda",
   "pharmaname": "New Golden",
   "companyname": "Newgenics Pharma"
  },
  {
   "name": "Newtrimd",
   "location": "Nexgenn",
   "pharmaname": "Next Remedies",
   "companyname": "Nhpl"
  },
  {
   "name": "Nicholas Actis Division",
   "location": "Nicholas Askar Division",
   "pharmaname": "Nicholas Cadance",
   "companyname": "Nicholas Cardex Division"
  },
  {
   "name": "Nicholas Cognex Division",
   "location": "Nicholas Extra Care Division",
   "pharmaname": "Nicholas Glotek Division",
   "companyname": "Nicholas Multi Speciality Divi"
  },
  {
   "name": "Nicholas Solitaire Division",
   "location": "Nicholas Zestek Division",
   "pharmaname": "Nicholas Zivion Division",
   "companyname": "Nicholas(Consumer)"
  },
  {
   "name": "Nicolas Pain Management",
   "location": "Nirlife",
   "pharmaname": "Nitta Gelatin India Ltd.",
   "companyname": "Niyamaa Life Sciences"
  },
  {
   "name": "Nouvelle",
   "location": "Nova Sea Pharma",
   "pharmaname": "Novacare",
   "companyname": "Novartis"
  },
  {
   "name": "Novartis India Ltd(Cvm)",
   "location": "Novartis India Ltd(Primary Care",
   "pharmaname": "Novartis India Pvt Ltd",
   "companyname": "Novartis Inidia Ltd(Mature )"
  },
  {
   "name": "Novique Life Sciences",
   "location": "Novo Nordisk.",
   "pharmaname": "Nuface Pharma",
   "companyname": "Nulife Pharmaceutivals"
  },
  {
   "name": "Nuoveau Medicament P Ltd",
   "location": "Nutraculture",
   "pharmaname": "Nutri Synapzz",
   "companyname": "Nutri Synapzz"
  },
  {
   "name": "Oaknet Healthcare",
   "location": "Oaknet Life Sciences",
   "pharmaname": "Oberderma Healthcare",
   "companyname": "Olive Healthcare"
  },
  {
   "name": "Optho Pharma",
   "location": "Optho Remedies Ltd",
   "pharmaname": "Orca Health",
   "companyname": "Ordain (Atf)"
  },
  {
   "name": "Ordain (Ctf)",
   "location": "Ordain (Ptf)",
   "pharmaname": "Ordain(Dtf)",
   "companyname": "Organon"
  },
  {
   "name": "Orthokem",
   "location": "Owshadh Pharma",
   "pharmaname": "Oxygene",
   "companyname": "Ozeil Pharmaceuticals"
  },
  {
   "name": "Ozone Pharmaceuticals",
   "location": "P&P Dermaceuticals",
   "pharmaname": "Pals Pharma",
   "companyname": "Panacea Biotec Ltd,"
  },
  {
   "name": "Pankaja Kasthuri",
   "location": "Panopharm",
   "pharmaname": "Paradigm Pharmaceuticals",
   "companyname": "Paras Pharma."
  },
  {
   "name": "Parazelsus",
   "location": "Park Davis",
   "pharmaname": "Pavan Medi Sells",
   "companyname": "Pearl Pharma"
  },
  {
   "name": "Pegasus Forma Co India",
   "location": "Pending",
   "pharmaname": "Pensia Biotech",
   "companyname": "Pfizer India Ltd"
  },
  {
   "name": "Pfizer Limited Ii",
   "location": "Pharm Products",
   "pharmaname": "Pharma French Pharmaceuticals India P Ltd",
   "companyname": "Pharma Link"
  },
  {
   "name": "Pharma Products",
   "location": "Pharmafabrikon",
   "pharmaname": "Pharmanova Specialties",
   "companyname": "Pharmed Ltd"
  },
  {
   "name": "Pharmed Rigel Div.",
   "location": "Practomed Pharma",
   "pharmaname": "Pranavam Heathcare",
   "companyname": "Precia Pharma"
  },
  {
   "name": "Priomed Lifesciences Pvt Ltd",
   "location": "Pristine Bionics",
   "pharmaname": "Pristine Organics Pvt Ltd",
   "companyname": "Pristine Pharma India Pvt Ltd"
  },
  {
   "name": "Procarvit Food Products (India) (P) Ltd",
   "location": "Progro",
   "pharmaname": "Promark",
   "companyname": "Proqol Heathcare"
  },
  {
   "name": "Pulse Pharma",
   "location": "Pure Derma",
   "pharmaname": "Q Check Speciality Care(Space)",
   "companyname": "Q Check Speciality Care(Stream)"
  },
  {
   "name": "Qura+",
   "location": "R.B Pharmaceuticals",
   "pharmaname": "R.E.& Sons",
   "companyname": "Ra Ass"
  },
  {
   "name": "Race Pharma",
   "location": "Racel Pharmaceuticals",
   "pharmaname": "Rakshani Enterpeises",
   "companyname": "Ramaraju Surgicals"
  },
  {
   "name": "Ranbaxy (Solus)",
   "location": "Ranbaxy Consumer",
   "pharmaname": "Ranbaxy Crosland-I",
   "companyname": "Ranbaxy Croslands-Ii"
  },
  {
   "name": "Ranbaxy Cv Care",
   "location": "Ranbaxy Cv Division Ltd",
   "pharmaname": "Ranbaxy Cv Life",
   "companyname": "Ranbaxy Lab Ltd"
  },
  {
   "name": "Ranbaxy Maxima.",
   "location": "Ranbaxy Prima Land.",
   "pharmaname": "Ranbaxy Respiratory",
   "companyname": "Ranbaxy Rexel Stancare Ltd"
  },
  {
   "name": "Ranmarc Labs",
   "location": "Raptakoas",
   "pharmaname": "Rathna Hospital Supplies",
   "companyname": "Razia Life Science"
  },
  {
   "name": "Rb",
   "location": "Reckitt Benckiser",
   "pharmaname": "Red Beryl Pharmaceutials",
   "companyname": "Redmax Pharma"
  },
  {
   "name": "Redmed Medical Services",
   "location": "Reehaa Herbal Marketing",
   "pharmaname": "Reliance Re Life",
   "companyname": "Reliance Relinova"
  },
  {
   "name": "Remedy Medicos Enterprises",
   "location": "Renlabs Pharma Pvt Ltd",
   "pharmaname": "Renocare",
   "companyname": "Renomed Life Care"
  },
  {
   "name": "Resiline Pharmaceuticals Pvt Ltd",
   "location": "Respspine Remedies",
   "pharmaname": "Retrot Pharmaceuticals",
   "companyname": "Revival Pharma"
  },
  {
   "name": "Revival Pharma",
   "location": "Rhydburg Pharma Ltd",
   "pharmaname": "Rivpra Formulation Pvt.Ltd",
   "companyname": "Rk Pharmaceuticals"
  },
  {
   "name": "Roche Diagnostics",
   "location": "Roche Ltd",
   "pharmaname": "Roman Health Care",
   "companyname": "Roxi Biotech"
  },
  {
   "name": "Roxter Laboratories",
   "location": "Royal Hygiene Care Pvt Ltd",
   "pharmaname": "Rpg Life Sciences Limited",
   "companyname": "Rr Life Sciences"
  },
  {
   "name": "Rusan Health Care",
   "location": "Rx Jaguarz Agency",
   "pharmaname": "Rytus",
   "companyname": "S&S Pharma Zar"
  },
  {
   "name": "Saan Lab",
   "location": "Sabarish",
   "pharmaname": "Safe Medical",
   "companyname": "Saffron"
  },
  {
   "name": "Safire Pharma",
   "location": "Sakka Biotech",
   "pharmaname": "Samarth Life Sciences Pvt Ltd",
   "companyname": "Samarth Life Sciences Pvt Ltd"
  },
  {
   "name": "Samarth Pharma Pvt Ltd",
   "location": "Sami Labs Ltd",
   "pharmaname": "San Medis",
   "companyname": "Sandro Bio Pharma Pvt Ltd"
  },
  {
   "name": "Sangrose Laboratories Pvt Ltd",
   "location": "Sanjeevini",
   "pharmaname": "Sanmai",
   "companyname": "Sanofi Aventis - Cardio Divisn"
  },
  {
   "name": "Sanofi Aventis- Consumer",
   "location": "Sanofi Aventis-Cns",
   "pharmaname": "Sanofi Aventis-Diabeto",
   "companyname": "Sanofi Aventis-Hospital"
  },
  {
   "name": "Sanofi Aventis-Inernalmed",
   "location": "Sanofi Aventis-Oncology",
   "pharmaname": "Sanofi Aventis-Phc\/Base",
   "companyname": "Sanofi-Diabetic Cvn"
  },
  {
   "name": "Sante Mernaud",
   "location": "Sante Mernaud Pharmaceuticals",
   "pharmaname": "Sanzyme P.Ltd",
   "companyname": "Sarg Pharmaceuticals P Ltd"
  },
  {
   "name": "Sarvi Life",
   "location": "Sava Medica Limited",
   "pharmaname": "Say Healthcare Pvt.Ltd",
   "companyname": "Sedan Pharma"
  },
  {
   "name": "Seerum Institute",
   "location": "Segnus",
   "pharmaname": "Selvanila Pharma",
   "companyname": "Senaru Formulations Pvt Ltd"
  },
  {
   "name": "Senses Pharmaceuticals",
   "location": "Sentiss Pharma",
   "pharmaname": "Sentum Healthcare",
   "companyname": "Serdia Laboratories"
  },
  {
   "name": "Shankar Pharmacy",
   "location": "Shawin Health Care",
   "pharmaname": "Shield",
   "companyname": "Shine Drugs"
  },
  {
   "name": "Shree Varma",
   "location": "Shreya Life Sciences",
   "pharmaname": "Shri Renganayagi",
   "companyname": "Shri Vari Pharma Pvt Ltd"
  },
  {
   "name": "Shri Vari Remedies(P)Ltd",
   "location": "Shrrishti Healthcare Products(I) Pvt Ltd",
   "pharmaname": "Shubam Enterprises",
   "companyname": "Simrise Phamaceuticals"
  },
  {
   "name": "Sinsan Pharmaceuticals",
   "location": "Skn Organics P Ltd",
   "pharmaname": "Sky High Herbal",
   "companyname": "Smithsante"
  },
  {
   "name": "Smithson Pharma",
   "location": "Snigp",
   "pharmaname": "Solis Pharmaceuticals",
   "companyname": "Solsun Lifecare"
  },
  {
   "name": "Solvay Pharma I St Division",
   "location": "Solvay Pharma Iiird Div.",
   "pharmaname": "Solvay Pharma Iind Div.",
   "companyname": "Sowmiya Pharma"
  },
  {
   "name": "Spb Pharma",
   "location": "Spectraa",
   "pharmaname": "Spero Medicare",
   "companyname": "Spiruolina"
  },
  {
   "name": "Spoosid Pharmaceuticals",
   "location": "Sree Vengateswara Distributors",
   "pharmaname": "Sresan Pharmaceuticals",
   "companyname": "Srh Foods"
  },
  {
   "name": "Sri Medicare",
   "location": "Sri Srinivasa Pharma",
   "pharmaname": "Sri Sudharsan",
   "companyname": "Sri Vinayaga"
  },
  {
   "name": "Sriha Pharmaceticals",
   "location": "Stadmed Pvt",
   "pharmaname": "Stature Life",
   "companyname": "Stedman Pharamcaeuticals Pvt Ltd"
  },
  {
   "name": "Stericlot Bandages Pvt",
   "location": "Sterling Medico",
   "pharmaname": "Strategi",
   "companyname": "Sujatha Bio Teck"
  },
  {
   "name": "Sun Arian",
   "location": "Sun Avesta",
   "pharmaname": "Sun Avior",
   "companyname": "Sun Azura"
  },
  {
   "name": "Sun General.",
   "location": "Sun Inca",
   "pharmaname": "Sun Inca Uro",
   "companyname": "Sun Milmet"
  },
  {
   "name": "Sun Ortus",
   "location": "Sun Pharma",
   "pharmaname": "Sun Radiant",
   "companyname": "Sun Sirius Division"
  },
  {
   "name": "Sun Solaries",
   "location": "Sun Spectra",
   "pharmaname": "Sun Symbiosis",
   "companyname": "Sun Synergy"
  },
  {
   "name": "Sundar Diabetic",
   "location": "Sundyota Numandis",
   "pharmaname": "Sunlife",
   "companyname": "Sunrise Pharmaceuticals"
  },
  {
   "name": "Sunways India Pvt Ltd(Ii)",
   "location": "Sunways(India)Pvt Ltd,",
   "pharmaname": "Surelife Media Techn Pvt Ltd",
   "companyname": "Surgiwear India Ltd"
  },
  {
   "name": "Suriya Pharmaceuticals",
   "location": "Suxes Healthcare Pvt Ltd",
   "pharmaname": "Svizera Health Care",
   "companyname": "Swizer Biomedicare"
  },
  {
   "name": "Synchroz Pharmaceuticals Pvt Ltd",
   "location": "Synergen",
   "pharmaname": "Synerheal Pharmaceuticals",
   "companyname": "Systopic Laboratories Pvt Ltd"
  },
  {
   "name": "T T Pharma Associates",
   "location": "T.T.K.Healthcare.",
   "pharmaname": "T.T.K.Pharma",
   "companyname": "T.T.K.Pharma-Ii"
  },
  {
   "name": "Tablets India (Vibranz)",
   "location": "Tablets India Ltd",
   "pharmaname": "Tabliz Life Sciencespvt Ltd",
   "companyname": "Talent Healthcare"
  },
  {
   "name": "Tanmed Pharmaxeuticals",
   "location": "Tasmed",
   "pharmaname": "Test",
   "companyname": "The Arya Vaidya"
  },
  {
   "name": "The Trinity Pharmaceuticals",
   "location": "Theia Health Care",
   "pharmaname": "Themis",
   "companyname": "Themis Medicare"
  },
  {
   "name": "Timek Agencies",
   "location": "Timon Pharmaceuticals",
   "pharmaname": "Tn Powder",
   "companyname": "Torrent"
  },
  {
   "name": "Torrent - Elder",
   "location": "Torrent ( Xtra Urban)",
   "pharmaname": "Torrent Axon.",
   "companyname": "Torrent Pharma (Azuca)"
  },
  {
   "name": "Torrent Pharma (Mind)",
   "location": "Torrent Pharma (Neuron)",
   "pharmaname": "Torrent Pharma (P Sycan)",
   "companyname": "Torrent Pharma(Delta)"
  },
  {
   "name": "Torrent Pharma(Vista)",
   "location": "Torrent Sensa.",
   "pharmaname": "Torrent(Psycan-Cnd)",
   "companyname": "Torrent-9"
  },
  {
   "name": "Torrentpharma(Prima)",
   "location": "Touch Pill Pharma",
   "pharmaname": "Transcend Therapeutics Pvt Ltd",
   "companyname": "Tricos Dermatologics"
  },
  {
   "name": "Trikona Pharma",
   "location": "Trine Pharmaceuticals",
   "pharmaname": "Tripoint Healthcare",
   "companyname": "Triton Healthcare"
  },
  {
   "name": "Tritonia Pharmaceuticals (Opc) Pvt Ltd",
   "location": "Troikaa Pharmaceuticals",
   "pharmaname": "Uas Pharmaceuticals Pvt Ltd",
   "companyname": "Uni Lab Ltd"
  },
  {
   "name": "Uni San Pharmaceuticals",
   "location": "Unicare",
   "pharmaname": "Unichem Cd Div.",
   "companyname": "Unichem Cv Divison."
  },
  {
   "name": "Unichem Integra.",
   "location": "Unichem Nf Divison.",
   "pharmaname": "Unichem-Neu Foreva",
   "companyname": "Unimax Medicare"
  },
  {
   "name": "Uniorange Lifecare Pvt Ltd",
   "location": "Uniq Medicare",
   "pharmaname": "Unique Pharma Ltd",
   "companyname": "United Pharma & Surgicals"
  },
  {
   "name": "Universal Medicare Ii",
   "location": "Universal Medicare Pvt Ltd",
   "pharmaname": "Universal Pharma Otc",
   "companyname": "Ursa Pharma"
  },
  {
   "name": "Ursa Pharma India Pvt Ltd",
   "location": "Usv Ltd",
   "pharmaname": "Uth Healthcare",
   "companyname": "Utn"
  },
  {
   "name": "Uttam Agencies",
   "location": "Vee Medi Biotech",
   "pharmaname": "Velite Healthcare",
   "companyname": "Venturian Health Care"
  },
  {
   "name": "Veritaz Healthcare Ltd",
   "location": "Vestas Life Sciences",
   "pharmaname": "Vhb Cardion Divison",
   "companyname": "Vhb Life Sciences Gyneo Dvn"
  },
  {
   "name": "Vhb Life Sciences Inc",
   "location": "Vibgyor Health Care",
   "pharmaname": "Violet Medi",
   "companyname": "Virchow Biotech Pvt Ltd"
  },
  {
   "name": "Viswasoorya Pharmaceuticals",
   "location": "Vivimed V",
   "pharmaname": "Voldus Pharmaceuticals",
   "companyname": "Voris Lifesciences."
  },
  {
   "name": "Vysali Pharma",
   "location": "Walfs Pharma",
   "pharmaname": "Wallace Lifestyle",
   "companyname": "Wallace Pharmaceuticals"
  },
  {
   "name": "Wallace Rivela",
   "location": "Walter Bushnell Ltd",
   "pharmaname": "Walton Health Care",
   "companyname": "Walton Life Sciences"
  },
  {
   "name": "Wanbury Ltd",
   "location": "Warren Labs",
   "pharmaname": "We Can Pharma",
   "companyname": "Wellness Pharmacy"
  },
  {
   "name": "Wellworth Life Sceinces Pvt Ltd",
   "location": "Win Derma Pharmaceutical",
   "pharmaname": "Win Medicare Ltd",
   "companyname": "Win Medicare-Ii"
  },
  {
   "name": "Win Multicare",
   "location": "Win Pharma",
   "pharmaname": "Wings Biotech",
   "companyname": "Winnova Formulation"
  },
  {
   "name": "Wipro Ltd",
   "location": "Wiscon Pharmaceuticals",
   "pharmaname": "Wisdom Healthcare",
   "companyname": "Withral Product"
  },
  {
   "name": "Wockhardt Cardiology",
   "location": "Wockhardt Ltd(Speciality)",
   "pharmaname": "Wockhardt-Pharma Taurus Ii",
   "companyname": "Wockhardt-Spectra"
  },
  {
   "name": "Wockhart Xtanza",
   "location": "Wockhortt Labs.",
   "pharmaname": "Woodwords",
   "companyname": "Worth Medicine"
  },
  {
   "name": "Wyeth Limited",
   "location": "Wyeth Ltd(Consumer)",
   "pharmaname": "Wytro Mediez (P) Ltd",
   "companyname": "Xena Bio Herbals Pvt Ltd"
  },
  {
   "name": "Xeno Life Science",
   "location": "Xl Life Sciences",
   "pharmaname": "Xyris Lifesciences",
   "companyname": "Yuta International"
  },
  {
   "name": "Zee Laboratories",
   "location": "Zelous",
   "pharmaname": "Zenox Lifesciences Ltd",
   "companyname": "Zeta Formulation Pvt Ltd"
  },
  {
   "name": "Zinniz Life Sciences",
   "location": "Zivia Health",
   "pharmaname": "Zuventus Healthcare",
   "companyname": "Zydus Alidac(Fortiza)"
  },
  {
   "name": "Zydus Cadila Alidac",
   "location": "Zydus Cardiva",
   "pharmaname": "Zydus Cnd",
   "companyname": "Zydus Medica"
  },
  {
   "name": "Zydus Medica(Harvest)",
   "location": "Zydus Nutriva",
   "pharmaname": "Zydus Cnd",
   "companyname": "Zydus Medica"
  },
 ];