import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import "../../app/services/services.css";
import Gear from "../../../asset/gear.png";
import Radiotherapy from "../../../asset/radiotherapy.png";
import Vaccines from "../../../asset/vaccines.png";
import Medicine from "../../../asset/medicine.png";
import Cosmetics from "../../../asset/cosmetics.png";
import Gmedicine from "../../../asset/gmedicine.png"
import Imex from "../../../asset/imex.png"
import { BsGearWideConnected } from "react-icons/bs";
import { AnimationOnScroll } from "react-animation-on-scroll";
import "animate.css";
import axios from "axios";
import { Helmet } from "react-helmet";

const Services = () => {
  const [meddata, setmeddata] = useState([]);

  // useEffect(() => {
  // const url="https://jsonplaceholder.typicode.com/posts";
  // fetch(url).then(resp=>resp.json())
  // .then(resp=>setdata(resp))
  // }, [])

  useEffect(() => {
    axios
      .get("https://exapi.etabx.com/api/get/specialityCategories")
      .then((res) => {
        setmeddata(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Career page | Lifecarepharma</title>
        <meta name="title" content="Career page | Lifecarepharma" />
        <meta name="description" content="Looking to join our team of experts and thrive on your pharma skills? We encourage candidates to apply who share our commitment to excellence and making a positive impact in the lives of patients." />
        <meta name="keywords" content="Pharmaceautical wholesale distributors,pharmaceutical wholesalers, 
    wholesale pharma distributor,pharma distributors,pharmaceutical wholesale distributors,medicine wholesale distributor, 
    medicine distributorship,pharma distribution companies in Chennai, pharma import and export, speciality medicine wholesaler,
    speciality medicine distributor,speciality pharma distributor, specialty distributors pharmaceuticals, Pharmaceutical distribution Company, Pharmaceutical Distribution Companies in India, 
    Pharmaceutical Distributors in India,Top Pharmaceutical Distribution Companies in India,Pharmaceutical Distribution Companies in Tamil Nadu,Online Pharmacy India, Medicine Online, Purchase Medicine Online "></meta>
      </Helmet>
      <div className={"services-bg"}>
        <Container>
          <AnimationOnScroll
            animateOnce={true}
            animateIn="animate__fadeInRight"
          >
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                height: "40vh",
              }}
            >
              <div className={"servicespg-heading"}>
                <p>
                  <span>Home</span> | <span>Services</span>
                </p>
                <h1>Services</h1>
              </div>
            </div>
          </AnimationOnScroll>
        </Container>
      </div>
      <div className={"py-5"}>
        <Container>
          <div className={"row service-row py-5"}>
            <div className={"col-lg-5 col-md-6 col-sm-12 "}>
              <AnimationOnScroll
                animateOnce={true}
                animateIn="animate__fadeInLeft"
              >
                <div className={"service1"}>
                  <div className={"ono-content"}>
                    <h5>ONCOLOGY PRODUCTS</h5>
                    <p>
                      Lifecare Pharma takes particular interest in cancer-ridden
                      patients. We strive to cater to the need for speciality
                      oncology medicines to the patients from our shelf and also
                      procuring from other sources on an S.O.S basis. We offer a
                      wide range of super speciality medicine, including all
                      kinds of cancers like breast, ovarian, blood, lung,
                      prostate, and ovarian cancers.
                    </p>
                  </div>
                  <div className={"ono-img"}>
                    <img src={Radiotherapy} alt='Oncology products lifecare pharma.com' />
                  </div>
                </div>

                <div className={"service1 py-5  "}>
                  <div className={"vac-content "}>
                    <h5>VACCINES</h5>
                    <p>
                      Injectibles, mainly vaccines are kept in adequate stock
                      with us. Its supplied on regular orders from retailers or
                      dispatched by Institutional delivery staff on an urgent
                      basis.
                    </p>
                  </div>
                  <div className={"vac-img"}>
                    <img src={Vaccines} alt="Vaccines lifecarepharma.com" />
                  </div>
                </div>
              </AnimationOnScroll>
            </div>

            <div
              className={
                " service-gear col-lg-2 col-md-7 col-sm-12 d-flex justify-content-center align-items-center"
              }
            >
              <AnimationOnScroll
                animateOnce={true}
                animateIn="animate__fadeInUp"
              >
                <div className="service_gear2">
                  <BsGearWideConnected className={"icon"} />
                  {/* <img src={Gear} width="100%" /> */}
                </div>
              </AnimationOnScroll>
            </div>

            <div className={"col-lg-5 col-md-6 col-sm-12 "}>
              <AnimationOnScroll
                animateOnce={true}
                animateIn="animate__fadeInRight"
              >
                <div className={"service1"}>
                  <div className={"med-img"}>
                    <img src={Medicine} alt="Specialty Medicines lifecarepharma.com" />
                  </div>
                  <div className={"med-content"}>
                    <h5>SPECIALITY MEDICINES</h5>
                    <p>
                      We offer a wide range of super-specialty medicines for
                      transplant, nephrology, urology, cardiology,
                      dermatology,hepatology&hormonal medicines. We have our
                      customers ranging from clinics to specialty hospitals to
                      retailers, we always prioritize the urgent requirements of
                      specialities considering the situations to serve the
                      needed.
                    </p>
                  </div>
                </div>

                <div className={"service1 py-5 "}>
                  <div className={"cos-img"}>
                    <img src={Cosmetics} alt="Cosmetic products lifecarepharma.com" />
                  </div>
                  <div className={"cos-content"}>
                    <h5>COSMETIC PRODUCTS</h5>
                    <p>
                      All ethically promoted Dermatologicals / Cosmetologicals
                      from renowned Companies are available with us. Skin, Hair,
                      and Nail Doctor clinics/hospitals with their own
                      dispensing can approach for regular supplies.
                    </p>
                  </div>
                </div>
              </AnimationOnScroll>
            </div>

            <div className={"col-lg-6 col-md-6 col-sm-12 py-1 "}>
              <AnimationOnScroll
                animateOnce={true}
                animateIn="animate__fadeInLeft"
              >
                <div className={"service1"}>
                  <div className={"ono-content"}>
                    <h5>GENERAL MEDICINES</h5>
                    <p>
                      Our biggest strength is our SKUs. We are direct stockists
                      for about 450 companies with 100000 products readily
                      available on our racks. When it comes to finding the
                      general medications you need; our company has got you
                      covered. Our staffs strive to work hard to ensure each
                      retailer or hospital's demand is met with fast and
                      friendly service.
                    </p>
                  </div>
                  <div className={"ono-img"}>
                    <img src={Gmedicine} alt="General medicines lifecarepharma.com" />
                  </div>
                </div>
              </AnimationOnScroll>
            </div>

            <div className={"col-lg-6 col-md-6 col-sm-12 py-1"}>
              <AnimationOnScroll
                animateOnce={true}
                animateIn="animate__fadeInRight"
              >
                <div className={"service1"}>
                  <div className={"med-img"}>
                    <img src={Imex} alt="Import & Export lifecarepharma.com" />
                  </div>
                  <div className={"med-content"}>
                    <h5>IMPORT & EXPORT</h5>
                    <p>
                      Lifecare Pharma deals with import and export of
                      generalmedicines, speciality drugs and other
                      pharmaceutical products from and to various parts of the
                      world. We aim to become your single source of healthcare
                      supplies by operating in all categories.
                    </p>
                  </div>
                </div>
              </AnimationOnScroll>
            </div>
          </div>
        </Container>
      </div>
      <Container>
        <div>
          <div className={" row category_main"}>
            <div className={"col-lg-12 category_heading"}>
              <h1>
                Our Speciality <span>Products</span>{" "}
              </h1>
            </div>
            {meddata.map((value) => {
              return (
                <div
                  className={
                    " col-xl-2 col-lg-4 col-md-4 col-sm-6 col-6 category_image"
                  }
                >
                  <AnimationOnScroll
                    animateOnce={true}
                    animateIn="animate__fadeInRight"
                  >
                    <div className={"categoty_list"}>
                      <img src={value.logoUrl} />
                      <div>
                        <h5> {value.name} </h5>
                      </div>
                    </div>
                  </AnimationOnScroll>
                </div>
              );
            })}
          </div>
        </div>
      </Container>
    </>
  );
};

export default Services;
