import React from "react";
import { Container } from "react-bootstrap";
import "../../home/section/ourservices.css";
import Medicine from "../../../../asset/medicine.png";
import Cosmetics from "../../../../asset/cosmetics.png";
import Radiotherapy from "../../../../asset/radiotherapy.png";
import Vaccines from "../../../../asset/vaccines.png";
import 'animate.css'
import { AnimationOnScroll } from "react-animation-on-scroll";
import { Fade } from "react-reveal";

const Ourservices = () => {
  return (
    <section>
      <div className={"bg-section"}>
        <Container>
            <Fade bottom>

          
          <div className={"row "}>
            <div className={" col-lg-12 services-heading"}>
              <h2>Our <span>Services</span> </h2>
            </div>
            
            <div className={"col-lg-3 col-md-6 col-sm-12 service-container "}>
              <div className={"services-circle1"}>
                <img src={Radiotherapy} />
              </div>
              <h5>ONCOLOGY PRODUCTS</h5>
              <p>
                Lifecare Pharmaceuticals takes special interests for cancer
                ridden patients. We strive to reach speciality oncology
                medicines to the patients from our shelf and also procuring from
                other sources on s.o.s basis.
              </p>
            </div>
            <div className={"col-lg-3 col-md-6 col-sm-12 service-container "}>
              <div className={"services-circle2"}>
                <img src={Vaccines} />
              </div>
              <h5>VACCINES</h5>
              <p>
                Injectibles, mainly vaccines are kept in adequate stock with us.
                Its supplied on regular orders from retailers or dispatched by
                Institutional delivery staff on urgent basis.
              </p>
            </div>
            <div className={"col-lg-3 col-md-6 col-sm-12  service-container "}>
              <div className={"services-circle3"}>
                <img src={Medicine} />
              </div>
              <h5>OTHER SPECIALITY MEDICINES</h5>
              <p>
                All speciality medicines like for nephrology, neurolgy, neuro
                psychiatry, urology etc., etc., to hormonol medicines which are
                promoted to medical proffession1and sold at authorised retailers
                are available with us at all times.
              </p>
            </div>
            <div className={"col-lg-3 col-md-6 col-sm-12 service-container "}>
              <div className={"services-circle4"}>
                <img src={Cosmetics} />
              </div>
              <h5>COSMETIC PRODUCTS</h5>
              <p>
                All ethically promoted Dermatologicals/Cosmetologicals from
                renowned Companies are available with us. Skin, Hair and Nail
                Doctorclinics/hospitals with own dispensing can approach for
                regular supplies.
              </p>
            </div>
        
          </div>
          </Fade>
        </Container>
      </div>
    </section>
  );
};

export default Ourservices;
