import React, { useEffect } from "react";
import { Container, Form, FormControl, InputGroup } from "react-bootstrap";
import "../../app/contact/contact.css";
import Contactdetails from "./section/contactdetails";
import Contactform from "./section/contactform";
import Location from "./section/location";
import { AnimationOnScroll } from "react-animation-on-scroll";
import 'animate.css'
import { Helmet } from "react-helmet";



const Contact = () => {

  useEffect(() => {

    window.scroll(0, 0)

  }, [])
  return (
    <>
      <Helmet>
        <title> Contact us | Lifecarepharma</title>
        <meta name="title" content=" Contact us | Lifecarepharma" />
        <meta name="description" content="Do you have any questions or enquiries. Fill out the form below and our team will get back in touch with you as soon as possible." />
      </Helmet>
      <div className={"contact-bg"}>
        <Container>
          <div
            style={{ display: "flex", height: "40vh", justifyContent: "end" }}
          >
            <div className={"contact-heading"}>
              <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInRight">

                <p>
                  <span>Home</span> | <span>Contact Us</span>{" "}
                </p>
                <h1>CONTACT US</h1>
              </AnimationOnScroll>
            </div>
          </div>
        </Container>
      </div>
      <Contactform />
      <Contactdetails />
      <Location />

    </>
  );
};

export default Contact;
