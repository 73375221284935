import React, { useState } from "react";
import { Button, Container, Modal, ModalHeader } from "react-bootstrap";
import "../../../../app/about/section/aboutdirector.css";
import Director1 from "../../../../../asset/director1.png";
import { AiOutlineCloseCircle } from "react-icons/ai";

import { BsArrowRightCircle } from "react-icons/bs";

const Aboutdirector = (props) => {
  const [show, Setshow] = useState(false);

  const handleClose = () => Setshow(false);
  const handleShow = () => Setshow(true);

  return (
    <>
      <Container>
        <div className={"director-img"} onClick={handleShow}>
          <img src={Director1} alt="Managing Director for lifecarepharma." />
          <div className={"director-name"}>
            <div>
              <h3>MR.S.A.RAMESH</h3>
              <p>The Managing Director</p>
            </div>
            <div className={"director-icon"}>
              <BsArrowRightCircle />
            </div>
          </div>
        </div>

        <Modal
          show={show}
          onHide={handleClose}
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <div className={"row model-box-image "}>
            <div className=" col-lg-12 d-flex justify-content-end ">
              <AiOutlineCloseCircle
                onClick={handleClose}
                className={"model-close-icon"}
              />
            </div>
            <div className={"col-lg-4"}>
              <img src={Director1} width="100%" />
            </div>
            <div className={" col-lg-8"}>
              <Modal.Body className={"model-box"}>
                <p>
                  Mr.S.A.Ramesh the Managing Director of our company is a
                  commerce graduate with rich experience in the pharmaceutical
                  industry. He started his career by establishing a
                  pharmaceutical retail outlet in the year 1996 in Chennai,
                  India. With his rich expertise in the industry he elevated
                  himself to the level of establishing LifeCare Pharma Pvt Ltd
                  Company involved in the distribution of hormone injections,
                  specialty products & vaccines in the year 2000. The
                  customer-focused approach and the in born enterprising ability
                  of our Managing Director have paved way in acquiring new
                  business, gaining the confidence of the clients, vendors and
                  external business partners.
                </p>
              </Modal.Body>
            </div>
            <div className={" col-lg-12"}>
              <Modal.Body className={"model-box"}>
                <p>
                  He was instrumental in establishing LifeCare Pharma Pvt Ltd in
                  the fast paced highly competitive Pharmaceutical Industry by
                  capitalizing the distributorship for more than 280 companies
                  in distributing their products to more than 2800 retail
                  outlets across Tamil Nadu, India. Presently the company has a
                  turnover of USD 1.5 Billion dealing with more than 35000
                  products serving leading corporate hospitals and Government
                  sectors.
                  <br />
                  <br />
                  Mr. S.A. Ramesh Has Been Elected As The Treasurer Of Tamil
                  Nadu Chemist And Druggists Association (TNCDA). This Is
                  Recognition For His Dedication, Commitment And Hard Work For
                  The Development Of The Pharmaceutical Industry And Its
                  Fraternity. Prior To This He Has Been The President Of CCDA .
                  TNCDA Is Bound To Be Hugely Benefitted From His Rich And Vast
                  Experience.
                  <br />
                  <br />
                  Trained under his visionary father, Mr. Hariharan Ramesh has
                  learnt all the nuances of the business at a very young age.
                  Passionate, progressive and focused he will be taking the
                  Organizational growth to higher levels with his innovative
                  entrepreneur skills. Changes are already visible with many of
                  his decisions are on right path of success for the
                  organization.
                </p>
              </Modal.Body>
            </div>
          </div>
        </Modal>
      </Container>
    </>
  );
};

export default Aboutdirector;
